import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Power, PowerOff, Clock } from "lucide-react";

interface OperationalEvent {
  kernel_id: string;
  event_time: string;
  event_type: string;
  runtime_minutes: number | null;
}

interface HvacEventsLogProps {
  events: OperationalEvent[];
}

export const HvacEventsLog = ({ events }: HvacEventsLogProps) => {
  // Format the timestamp for display
  const formatTimestamp = (timestamp: string) => {
    // Handle timestamp format with .%fZ
    const cleanTimestamp = timestamp.replace(".%fZ", ".000Z");
    const date = new Date(cleanTimestamp);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      fullDateTime: `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`,
    };
  };

  return (
    <Card className="col-span-2">
      <CardHeader>
        <CardTitle>Operational Events</CardTitle>
        <CardDescription>Recent system startup and shutdown events</CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Event</TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Time</TableHead>
              <TableHead>Duration</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {events.slice(0, 10).map((event, index) => {
              const formattedTime = formatTimestamp(event.event_time);

              return (
                <TableRow key={index}>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      {event.event_type === "startup" ? (
                        <Power className="h-4 w-4 text-green-500" />
                      ) : (
                        <PowerOff className="h-4 w-4 text-gray-500" />
                      )}
                      <span className="capitalize">{event.event_type}</span>
                    </div>
                  </TableCell>
                  <TableCell>{formattedTime.date}</TableCell>
                  <TableCell>{formattedTime.time}</TableCell>
                  <TableCell>
                    {event.runtime_minutes !== null ? (
                      <div className="flex items-center gap-1">
                        <Clock className="h-3 w-3 text-muted-foreground" />
                        <span>
                          {event.runtime_minutes > 60
                            ? `${Math.floor(event.runtime_minutes / 60)}h ${event.runtime_minutes % 60}m`
                            : `${event.runtime_minutes}m`}
                        </span>
                      </div>
                    ) : (
                      <span className="text-muted-foreground text-xs">—</span>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
