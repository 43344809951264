// hvac-analytics-store.ts
import { create } from "zustand";
import { httpApi } from "@/http-api";
import { HvacAnalytics } from "../../../../../../services/cool-guardian/src/models/hvac";

type HvacAnalyticsState = {
  analytics: HvacAnalytics | null;
  isLoading: boolean;
  error: string | null;
};

type HvacAnalyticsActions = {
  fetchAnalytics: (kernelId: string) => Promise<void>;
  reset: () => void;
};

export const useHvacAnalyticsStore = create<HvacAnalyticsState & HvacAnalyticsActions>((set) => ({
  analytics: null,
  isLoading: false,
  error: null,

  fetchAnalytics: async (kernelId: string) => {
    set({ isLoading: true, error: null });
    try {
      const { data } = await httpApi.getHvacAnalytics(kernelId);
      set({ analytics: data, isLoading: false });
    } catch (error) {
      console.error("Failed to fetch HVAC analytics:", error);
      set({ error: "Failed to load HVAC analytics data", isLoading: false });
    }
  },

  reset: () => {
    set({ analytics: null, isLoading: false, error: null });
  },
}));
