import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog";
  import { Button } from "@/components/ui/button";
  import { useBillingStore } from "@/features/dashboard/billing/billing-store";
  import { useEffect, useState } from "react";
import React from "react";
  
  export const SubscriptionRemoveDialog = ({ subscriptionId, deviceId, open, onOpenChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const cancelSubscription = useBillingStore((state) => state.cancelSubscription);
  
    // Reset form when dialog opens
    useEffect(() => {
      if (open) {       
        setError("");
      }
    }, [open]);
  
    const handleSubscriptionRemoval = async () => {   
      
      setError("");  
      try {
        await cancelSubscription(deviceId, subscriptionId);
        onOpenChange(false);        
        setTimeout(() => { window.location.reload();}, 1500);
      } catch (err) {
        setError("Failed to remove the subscription.");
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent onClick={(e) => e.stopPropagation()}>
          <DialogHeader>
            <DialogTitle>Remove Subscription</DialogTitle>
            <DialogDescription>
              Are you sure you want to remove the subscription.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">            
            {error && <p className="text-sm text-destructive">{error}</p>}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubscriptionRemoval} disabled={isLoading}>
              {isLoading ? "Removing..." : "Remove"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  