import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Clock, Activity, Timer, Percent } from "lucide-react";

interface DutyCycleData {
  kernel_id: string;
  period: string;
  time_grain: string;
  total_cycles: number;
  avg_cycle_duration_minutes: number;
  total_runtime_minutes: number;
  runtime_percentage: number;
}

interface HvacDutyCyclesProps {
  data: {
    daily: DutyCycleData;
    weekly: DutyCycleData;
    monthly: DutyCycleData;
  };
}

const DutyCycleCard = ({ title, data, icon: Icon }: { title: string; data: DutyCycleData; icon: any }) => {
  // Format the period label
  let periodLabel = data.period;
  if (data.time_grain === "daily") {
    const date = new Date(data.period);
    periodLabel = date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
  } else if (data.time_grain === "weekly") {
    const [year, week] = data.period.split("-W");
    periodLabel = `Week ${week}, ${year}`;
  } else if (data.time_grain === "monthly") {
    const [year, month] = data.period.split("-");
    const monthName = new Date(parseInt(year), parseInt(month) - 1, 1).toLocaleDateString("en-US", { month: "long" });
    periodLabel = `${monthName} ${year}`;
  }

  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="grid grid-cols-[1fr_auto] items-start gap-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-3">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground">Total Cycles</span>
              <span className="text-xl font-bold">{data.total_cycles}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground">Avg Cycle Duration</span>
              <span className="text-xl font-bold">{data.avg_cycle_duration_minutes.toFixed(1)} min</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground">Total Runtime</span>
              <span className="text-xl font-bold">{data.total_runtime_minutes} min</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs text-muted-foreground">Runtime %</span>
              <span className="text-xl font-bold">{data.runtime_percentage.toFixed(1)}%</span>
            </div>
          </div>

          <div className="flex items-center gap-1 text-xs text-muted-foreground border-t border-gray-100 pt-2 mt-1">
            <Clock className="h-3 w-3" />
            <span className="font-medium">Period:</span> {periodLabel}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const HvacDutyCycles = ({ data }: HvacDutyCyclesProps) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Duty Cycles</h3>
      </div>

      <div className="grid gap-4 xlg:grid-cols-3">
      {data.daily!=null && <DutyCycleCard title="Daily Duty Cycle" data={data.daily} icon={Activity} />}
      {data.weekly!=null && <DutyCycleCard title="Weekly Duty Cycle" data={data.weekly} icon={Timer} />}
      {data.monthly!=null &&  <DutyCycleCard title="Monthly Duty Cycle" data={data.monthly} icon={Percent} />}
      </div>
    </div>
  );
};
