import WhiteLogo from "@/assets/Cyan-black-logo.png";
import DarkLogo from "@/assets/Logo-DarkMode.png";
import { useTheme } from "@/components/ui/theme-provider";
import { useNavigate } from "react-router-dom";

export const TopBarAuth = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const showLogo = () => {
    // console.log("Theme", theme);
    if (theme === "dark") {
      return <img src={DarkLogo} alt="DarkLogo" className="h-8 md:h-10 object-fill"></img>;
    } else {
      return <img src={WhiteLogo} alt="WhiteLogo" className="h-8 md:h-10 object-fill"></img>;
    }
  };

  return (
    <header className="sm:h-auto">
      <div className="flex justify-center">
        <div className="flex flex-row w-full h-20 bg-White/30 backdrop-blur items-center justify-between">
          <div className="overflow-x-hidden md:flex">
            <button className="flex items-center sm:pr-6 mb-2 mx-4 hover:cursor-pointer" onClick={() => navigate("/dashboard/")}>
              {showLogo()}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
