import { Customer, InstalledDevice } from "@rtbot-dev/json-schemas";
import { Settings as SettingsIcon, BookUser, Minus, Plus, Mail, Phone, Building } from "lucide-react";
import { useState } from "react";
import React from "react";
import { DeviceCard } from "../device/DeviceCard";

type CustomerSectionProps = {
  customer: Customer;
  devices: InstalledDevice[];
  selectedDevice: InstalledDevice | null;
  onDeviceSelect: (device: InstalledDevice) => void;
};

export const CustomerSection = React.memo(
  ({ customer, devices, selectedDevice, onDeviceSelect }: CustomerSectionProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="mb-6">
        <div className="flex items-center space-x-2 mb-3">
          <BookUser className={`h-4 w-4 ${isExpanded ? "" : "text-muted-foreground"} `} />
          <h3>{customer.contact.emailAddress}</h3>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`
                overflow-hidden 
                transition-all 
                duration-200 
                ease-in-out
                ${isExpanded ? "opacity-100 p-3" : "opacity-50 p-0"}`}
          >
            {isExpanded ? <Minus className="h-4 w-4" /> : <Plus className="h-4 w-4" />}
          </button>
        </div>
        <div
          className={`mb-3 rounded-md overflow-hidden transition-all duration-200 ease-in-out
              ${isExpanded ? "max-h-40 opacity-100" : "max-h-0 opacity-0 p-0"}`}
        >
          <div className="mb-3 space-y-2 bg-secondary/20 p-3 rounded-md">
            <div className="flex items-center space-x-2">
              <Mail className="h-4 w-4" />
              <span className="text-sm">{customer.contact.emailAddress}</span>
            </div>
            {customer.contact.firstName && (
              <div className="flex items-center space-x-2">
                <Building className="h-4 w-4" />
                <span className="text-sm">
                  {customer.contact.suffix ? customer.contact.suffix + " " : ""}
                  {customer.contact.firstName} {customer.contact.lastName}
                </span>
              </div>
            )}
            {customer.contact.phoneNumber && (
              <div className="flex items-center space-x-2">
                <Phone className="h-4 w-4" />
                <span className="text-sm">{customer.contact.phoneNumber}</span>
              </div>
            )}
          </div>
        </div>

        <div className="space-y-2 px-3">
          {devices.map((device) => (
            <DeviceCard
              key={device.deviceId}
              device={device}
              isCustomer={false}
              isSelected={selectedDevice?.deviceId === device.deviceId}
              onClick={() => onDeviceSelect(device)}
            />
          ))}
        </div>
      </div>
    );
  }
);
