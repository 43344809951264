import { create, StoreApi, UseBoundStore } from "zustand";
import { Equipment, InstalledDevice, NewCustomerSetup } from "@rtbot-dev/json-schemas";
import { httpApi } from "@/http-api";

export type PartnerStoreState = {
  customerSetup: Omit<NewCustomerSetup, "monitoring"> | null;
  deviceSetup: Equipment | null;
  customerEmail: string | null;
};

export type PartnerStoreActions = {
  setCustomerSetup: (customerSetup: Omit<NewCustomerSetup, "monitoring"> | null) => void;
  setDeviceSetup: (customerSetup: Equipment | null) => void;
  setCustomerEmail: (customerEmail:string | null) => void;
};

export type PartnerStore = PartnerStoreState & PartnerStoreActions;

export const usePartnerStore: UseBoundStore<StoreApi<PartnerStore>> = create<PartnerStore>((set, get) => ({
  //Determine whether to place data in placeholder here or in the library, and whether the check is performed after the first change in each field
  customerSetup: null,
  deviceSetup: null,
  customerEmail: null,
  setCustomerSetup: (customerSetup: Omit<NewCustomerSetup, "monitoring"> | null) => {    
    set(() => ({ customerSetup: customerSetup  }));
  },  
  setDeviceSetup: (deviceSetup: Equipment | null) => {    
    set(() => ({ deviceSetup : deviceSetup  }));
  },
  setCustomerEmail: (customerEmail: string | null) => {
    set(() => ({ customerEmail: customerEmail }));
  },
}));
