import { useEffect, useRef, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Thermometer, ArrowDownLeft, Info } from "lucide-react";
import * as d3 from "d3";

interface MeasurementPoint {
  measurement_time: string;
  superheat: number;
  subcooling: number;
}

interface SuperheatChartProps {
  data: MeasurementPoint[];
}

export const HvacSuperheatChart = ({ data }: SuperheatChartProps) => {
  const contourRef = useRef<HTMLDivElement>(null);
  const [contourData, setContourData] = useState<any>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  if (!data) {
    console.warn("No data provided for superheat chart");
    return null;
  }

  // Calculate current values and averages
  const latestSuperheat = data.length > 0 ? data[0].superheat : 0;
  const latestSubcooling = data.length > 0 ? data[0].subcooling : 0;

  const avgSuperheat = data.length > 0 ? data.reduce((sum, point) => sum + point.superheat, 0) / data.length : 0;
  const avgSubcooling = data.length > 0 ? data.reduce((sum, point) => sum + point.subcooling, 0) / data.length : 0;

  // Determine system health based on values
  const getSuperheatHealth = (value: number) => {
    if (value < 5) return { status: "Low", color: "text-amber-500" };
    if (value > 30) return { status: "High", color: "text-amber-500" };
    return { status: "Normal", color: "text-green-500" };
  };

  const getSubcoolingHealth = (value: number) => {
    if (value < 4) return { status: "Low", color: "text-amber-500" };
    if (value > 15) return { status: "High", color: "text-amber-500" };
    return { status: "Normal", color: "text-green-500" };
  };

  const superheatHealth = getSuperheatHealth(latestSuperheat);
  const subcoolingHealth = getSubcoolingHealth(latestSubcooling);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // State gets updated on resize
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Generate heatmap data
  useEffect(() => {
    if (data.length === 0 || !contourRef.current) return;

    const generateHeatmapData = (data: MeasurementPoint[]) => {
      const superheatValues = data.map((d) => Math.round(d.superheat));
      const subcoolingValues = data.map((d) => Math.round(d.subcooling));

      const xExtent = d3.extent(superheatValues) as [number, number];
      const yExtent = d3.extent(subcoolingValues) as [number, number];

      const xMin = Math.min(0, xExtent[0] - 2);
      const xMax = Math.max(40, xExtent[1] + 2);
      const yMin = Math.min(0, yExtent[0] - 2);
      const yMax = Math.max(20, yExtent[1] + 2);

      const histogramObj: { [key: string]: number } = {};
      data.forEach((point) => {
        const xBin = Math.round(point.superheat);
        const yBin = Math.round(point.subcooling);
        const key = `${xBin},${yBin}`;
        histogramObj[key] = (histogramObj[key] || 0) + 1;
      });

      const densityData = Object.entries(histogramObj).map(([key, value]) => {
        const [x, y] = key.split(",").map(Number);
        return { x, y, value };
      });

      const maxFrequency = Math.max(...Object.values(histogramObj));

      return { densityData, xMin, xMax, yMin, yMax, maxFrequency };
    };

    setContourData(generateHeatmapData(data));
  }, [data]);

  // Render the heatmap
  useEffect(() => {
    if (!contourRef.current || !contourData) return;

    d3.select(contourRef.current).select("svg").remove();

    if (contourData.densityData.length === 0) {
      d3.select(contourRef.current)
        .append("div")
        .attr("class", "text-center py-12 text-gray-500")
        .text("Not enough data to generate heatmap");
      return;
    }

    const { densityData, xMin, xMax, yMin, yMax, maxFrequency } = contourData;

    const width = contourRef.current.clientWidth;
    const height = contourRef.current.clientHeight;
    const margin = { top: 20, right: 60, bottom: 50, left: 50 }; // Adjusted margins
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(contourRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    const xScale = d3
      .scaleLinear()
      .domain([xMin - 0.5, xMax + 0.5])
      .range([0, innerWidth]);
    const yScale = d3
      .scaleLinear()
      .domain([yMin - 0.5, yMax + 0.5])
      .range([innerHeight, 0]);

    const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, maxFrequency]);

    // Draw heatmap rectangles
    g.selectAll("rect.heatmap")
      .data(densityData)
      .enter()
      .append("rect")
      .attr("class", "heatmap")
      .attr("x", (d) => xScale(d.x - 0.5))
      .attr("width", (d) => xScale(d.x + 0.5) - xScale(d.x - 0.5))
      .attr("y", (d) => yScale(d.y + 0.5))
      .attr("height", (d) => yScale(d.y - 0.5) - yScale(d.y + 0.5))
      .attr("fill", (d) => colorScale(d.value))
      .attr("stroke", "none");

    // Add tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.8)")
      .style("color", "white")
      .style("padding", "5px")
      .style("border-radius", "3px")
      .style("pointer-events", "none")
      .style("visibility", "hidden");

    g.selectAll("rect.heatmap")
      .on("mouseover", (event, d) => {
        tooltip
          .style("visibility", "visible")
          .text(`Frequency: ${d.value}`)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 10}px`);
      })
      .on("mousemove", (event) => {
        tooltip.style("left", `${event.pageX + 10}px`).style("top", `${event.pageY - 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });

    // Grid lines restricted to normal range
    const normalSuperheatMin = 5;
    const normalSuperheatMax = 30;
    const normalSubcoolingMin = 4;
    const normalSubcoolingMax = 15;

    for (let i = normalSuperheatMin; i <= normalSuperheatMax; i++) {
      g.append("line")
        .attr("x1", xScale(i))
        .attr("x2", xScale(i))
        .attr("y1", yScale(normalSubcoolingMax))
        .attr("y2", yScale(normalSubcoolingMin))
        .attr("stroke", "lightgray")
        .attr("stroke-width", 0.5);
    }

    for (let j = normalSubcoolingMin; j <= normalSubcoolingMax; j++) {
      g.append("line")
        .attr("x1", xScale(normalSuperheatMin))
        .attr("x2", xScale(normalSuperheatMax))
        .attr("y1", yScale(j))
        .attr("y2", yScale(j))
        .attr("stroke", "lightgray")
        .attr("stroke-width", 0.5);
    }

    // Normal zone boundary lines
    g.append("line")
      .attr("x1", xScale(normalSuperheatMin))
      .attr("x2", xScale(normalSuperheatMin))
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "green")
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "5,3");
    g.append("line")
      .attr("x1", xScale(normalSuperheatMax))
      .attr("x2", xScale(normalSuperheatMax))
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "green")
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "5,3");
    g.append("line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", yScale(normalSubcoolingMin))
      .attr("y2", yScale(normalSubcoolingMin))
      .attr("stroke", "green")
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "5,3");
    g.append("line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", yScale(normalSubcoolingMax))
      .attr("y2", yScale(normalSubcoolingMax))
      .attr("stroke", "green")
      .attr("stroke-width", 1.5)
      .attr("stroke-dasharray", "5,3");

    // Scatter plot
    g.selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(Math.round(d.superheat)))
      .attr("cy", (d) => yScale(Math.round(d.subcooling)))
      .attr("r", 2)
      .attr("fill", "rgba(0, 0, 0, 0.3)")
      .attr("stroke", "white")
      .attr("stroke-width", 0.3);

    // Highlight latest reading
    if (data.length > 0) {
      g.append("circle")
        .attr("cx", xScale(Math.round(data[0].superheat)))
        .attr("cy", yScale(Math.round(data[0].subcooling)))
        .attr("r", 6)
        .attr("fill", "none")
        .attr("stroke", "#2563eb")
        .attr("stroke-width", 2);
    }

    // Axes
    const xAxis = d3.axisBottom(xScale).tickValues(d3.range(xMin, xMax + 1, 5));
    const yAxis = d3.axisLeft(yScale).tickValues(d3.range(yMin, yMax + 1, 2));

    g.append("g").attr("transform", `translate(0,${innerHeight})`).call(xAxis);
    g.append("g").call(yAxis);

    // Axis labels
    g.append("text")
      .attr("x", innerWidth / 2)
      .attr("y", innerHeight + margin.bottom - 5) // Adjusted for space
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text("Superheat (°F)");

    g.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text("Subcooling (°F)");

    // Color legend
    const legendWidth = 20;
    const legendHeight = innerHeight / 2;
    const legendScale = d3.scaleLinear().domain([0, maxFrequency]).range([legendHeight, 0]);
    const legendAxis = d3.axisRight(legendScale).tickSize(0).ticks(5);

    const legend = svg.append("g").attr("transform", `translate(${width - margin.right + 10},${margin.top})`);

    const defs = svg.append("defs");
    const gradient = defs
      .append("linearGradient")
      .attr("id", "density-gradient")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%");

    const numStops = 10;
    for (let i = 0; i <= numStops; i++) {
      const t = i / numStops;
      gradient
        .append("stop")
        .attr("offset", `${t * 100}%`)
        .attr("stop-color", colorScale(t * maxFrequency));
    }

    legend
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", legendWidth)
      .attr("height", legendHeight)
      .style("fill", "url(#density-gradient)");

    legend.append("g").attr("transform", `translate(${legendWidth},0)`).call(legendAxis);

    legend
      .append("text")
      .attr("x", legendWidth / 2)
      .attr("y", -5)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .text("Frequency");

    // Clean up tooltip on unmount
    return () => {
      tooltip.remove();
    };
  }, [contourData, data, windowWidth]);

  return (
    <div className="space-y-4">
      {/* Header spanning full width */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Refrigerant Performance</h3>
      </div>

      {/* 2:1 Grid Layout */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {/* Graph Column (2 parts) */}
        <div className="lg:col-span-3">
          <Card className="h-full">
            <CardHeader>
              <CardTitle>Superheat & Subcooling Distribution</CardTitle>
              <CardDescription>
                Heatmap showing the most common operating conditions. Grid lines highlight the normal range (Superheat:
                5-30°F, Subcooling: 4-15°F).
              </CardDescription>
            </CardHeader>
            <CardContent className="px-4 pb-4 pt-0 overflow-hidden">
              <div ref={contourRef} className="w-full h-[50vh] min-h-[300px] max-h-[600px]"></div>
            </CardContent>
          </Card>
        </div>

        {/* Cards Column (1 part) */}
        <div className="lg:col-span-3 space-y-4">
          <Card>
            <CardHeader className="pb-2">
              <div className="grid grid-cols-[1fr_auto] items-start gap-2">
                <CardTitle className="text-sm font-medium">Current Superheat</CardTitle>
                <Thermometer className="h-4 w-4 text-muted-foreground" />
              </div>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col space-y-1.5">
                <div className="text-2xl font-bold">{latestSuperheat.toFixed(1)}°F</div>
                <div className="flex items-center gap-1">
                  <span className={`text-xs ${superheatHealth.color} font-medium`}>{superheatHealth.status}</span>
                  <span className="text-xs text-muted-foreground">(Avg: {avgSuperheat.toFixed(1)}°F)</span>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="pb-2">
              <div className="grid grid-cols-[1fr_auto] items-start gap-2">
                <CardTitle className="text-sm font-medium">Current Subcooling</CardTitle>
                <ArrowDownLeft className="h-4 w-4 text-muted-foreground" />
              </div>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col space-y-1.5">
                <div className="text-2xl font-bold">{latestSubcooling.toFixed(1)}°F</div>
                <div className="flex items-center gap-1">
                  <span className={`text-xs ${subcoolingHealth.color} font-medium`}>{subcoolingHealth.status}</span>
                  <span className="text-xs text-muted-foreground">(Avg: {avgSubcooling.toFixed(1)}°F)</span>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="pb-2">
              <div className="grid grid-cols-[1fr_auto] items-start gap-2">
                <CardTitle className="text-sm font-medium">System Health</CardTitle>
                <Info className="h-4 w-4 text-muted-foreground" />
              </div>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col space-y-1.5">
                <div className="text-sm">
                  {latestSuperheat > 5 && latestSuperheat < 30 && latestSubcooling > 4 && latestSubcooling < 15 ? (
                    <span className="text-green-500">System operating within normal parameters</span>
                  ) : (
                    <span className="text-amber-500">System may need attention</span>
                  )}
                </div>
                <div className="text-xs text-muted-foreground">Based on current superheat and subcooling readings</div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
