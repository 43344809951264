import { useEffect } from "react";
import { Activity, Power, Timer, Gauge, Fan } from "lucide-react";
import { useDashboardStore } from "../dashboard-store";
import { StatisticCard } from "../StatisticCard";
import { useHvacAnalyticsStore } from "../hvac-analytics-store";
import { HvacDutyCycles } from "./HvacDutyCycles";
import { HvacSuperheatChart } from "./HvacSuperheatChart";
import { HvacEventsLog } from "./HvacEventsLog";

export const DeviceStatistics = () => {
  const selectedDevice = useDashboardStore((state) => state.selectedDevice);
  const { analytics, isLoading, error, fetchAnalytics, reset } = useHvacAnalyticsStore();

  useEffect(() => {
    if (selectedDevice && selectedDevice.remoteKernelIds && (selectedDevice.remoteKernelIds.length > 0)) {
      const remoteKernelId = selectedDevice.remoteKernelIds[0];
      fetchAnalytics(remoteKernelId);

      return () => {
        reset();
      };
    }
  }, [selectedDevice?.remoteKernelIds, fetchAnalytics, reset]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center space-y-3">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary mx-auto"></div>
          <div>Loading analytics data...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md">
        <div className="font-bold">Error Loading Data</div>
        <div className="text-sm">{error}</div>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="bg-blue-50 border border-blue-200 text-blue-700 px-4 py-3 rounded-md">
        <div className="font-medium">No Analytics Data</div>
        <div className="text-sm">
          No data is available for this device. Please ensure the device is online and transmitting data.
        </div>
      </div>
    );
  }

  // Energy consumption statistics
  const energyStats = [
    ...(analytics.daily_comparison ? [{
      title: "Daily Consumption",
      value: analytics.daily_comparison.energy_kwh,
      unit: "kWh",
      icon: Power,
      pctChange: analytics.daily_comparison.pct_change,
      pctChangePeriod: "day",
      period: analytics.daily_comparison.period,
      periodType: "daily" as const,
    }] : []),
    ...(analytics.weekly_comparison ? [{
      title: "Weekly Consumption",
      value: analytics.weekly_comparison.energy_kwh,
      unit: "kWh",
      icon: Power,
      pctChange: analytics.weekly_comparison.pct_change,
      pctChangePeriod: "week",
      period: analytics.weekly_comparison.period,
      periodType: "weekly" as const,
    }] : []),
    ...(analytics.monthly_comparison ? [{
      title: "Monthly Consumption",
      value: analytics.monthly_comparison.energy_kwh,
      unit: "kWh",
      icon: Power,
      pctChange: analytics.monthly_comparison.pct_change,
      pctChangePeriod: "month",
      period: analytics.monthly_comparison.period,
      periodType: "monthly" as const,
    }] : []),
    ...(analytics.energy_overview ? [{
      title: "Average Power",
      value: analytics.energy_overview.avg_power_kw,
      unit: "kW",
      icon: Gauge,
      peak: analytics.energy_overview.peak_power_kw,
      showPeak: true,
      period: analytics.energy_overview.period,
      periodType: "daily" as const,
    }] : []),
  ];

  return (
    <div className="space-y-8">
      
      {/* Energy Consumption Section */}
      { (energyStats!=null && energyStats.length > 0) &&
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Energy Consumption</h3>
        </div>

        <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-4">
          {energyStats.map((stat, index) => (
            <StatisticCard key={index} {...stat} />
          ))}
        </div>
      </div>}

      {/* Duty Cycles Section */}
      {(analytics.duty_cycles.daily!=null || 
        analytics.duty_cycles.weekly!=null || 
        analytics.duty_cycles.monthly!=null)  && <HvacDutyCycles data={analytics.duty_cycles} />}

      {/* Refrigerant Performance Section */}
      {(analytics.superheat_subcooling_week!=null &&
      analytics.superheat_subcooling_week.length > 0) && <HvacSuperheatChart data={analytics.superheat_subcooling_week} />}

      {/* Events and HVAC Operation */}
      {(analytics.operational_events!=null && analytics.operational_events.length > 0) &&
      <div className="grid gap-4 md:grid-cols-2">
        <HvacEventsLog events={analytics.operational_events} />

        {/* Additional card for future expansion */}
        <div className="hidden md:block">{/* This is a placeholder for future content */}</div>
      </div>}
    </div>
  );
};
