"use client";

import React, { useState } from "react";
import * as Switch from "@radix-ui/react-switch";

const ToggleSwitch: React.FC<{ leftLabel: string, rightLabel: string, visibitily(value:boolean):void, check: boolean }> = ({leftLabel,rightLabel,visibitily, check}) => {
 

  const handleToggle = (checked: boolean) => {      
    if (visibitily) visibitily(checked);
  };

  return (
    <div className="flex items-center space-x-2">
      <label htmlFor="left" className="text-sm font-medium">
        {leftLabel}
      </label>
      <Switch.Root
        id="left"
        checked={!check}
        onCheckedChange={handleToggle}
        className="w-10 h-6 bg-blue-300 rounded-full"
      >
        <Switch.Thumb className="block w-4 h-4 bg-white rounded-full shadow-md transform translate-x-1 data-[state=checked]:translate-x-5 transition" />
      </Switch.Root>
      <label className="text-sm font-medium">
        {rightLabel}
      </label>
    </div>
  );
};

export { ToggleSwitch };