import { Step, StepItem, Stepper } from "@/components/ui/stepper";
import { TopBarAuth } from "@/features/dashboard/header/TopBarAuth";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { NewCustomerRegistrationForm } from "./customer/NewCustomerRegistrationForm";
import { NewEquipmentRegistrationForm } from "./equipment/NewEquipmentRegistrationForm";
import { ConfirmRegistration } from "./confirm-registration/ConfirmRegistration";

const steps = [{ label: "Customer" }, { label: "Equipment" }, { label: "Finish" }] satisfies StepItem[];

export const NewRegistration = () => {
  return (
    <div className="min-h-screen bg-background">
      <TopBarAuth />
      <div className="flex justify-center items-center">
        <Card className="w-full m-4 sm:m-0 sm:max-w-screen-2xl">
          <CardHeader className="flex justify-center w-full mt-5">
            <CardTitle className="text-center">New Registration</CardTitle>
          </CardHeader>
          <CardContent className="p-0 sm:p-6">
            <Stepper variant="circle-alt" initialStep={0} steps={steps}>
              {steps.map((stepProps, index) => {
                if (index === 0) {
                  return (
                    <Step key={stepProps.label} {...stepProps}>
                      <div className="mt-8">
                        <NewCustomerRegistrationForm />
                      </div>
                    </Step>
                  );
                } else if (index === 1) {
                  return (
                    <Step key={stepProps.label} {...stepProps}>
                      <div className="mt-8">
                        <NewEquipmentRegistrationForm />
                      </div>
                    </Step>
                  );
                } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <ConfirmRegistration />
                  </Step>
                );}
              })}
            </Stepper>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
