import * as React from "react";
import IconGoogle from "../../../assets/icons8-google.svg";
import { cn } from "@/components/utils";
import { useAuthStore } from "../auth-store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Icons } from "@/components/ui/icons";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function SignUpForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const {
    signInWithGoogle,
    signUp,
    user,
    error,
    clearError,
    isAcceptTerms,
    isAcceptPrivacyPolicy,
    setIsAcceptTerms,
    setIsAcceptPrivacyPolicy,
    isDialogTermsChecked,
    isDialogPrivacyChecked,
    setIsDialogTermsChecked,
    setIsDialogPrivacyChecked,
    isAcceptNotificationPartner,
    setIsAcceptNotificationPartner,
  } = useAuthStore((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const navigate = useNavigate();
  const { toast } = useToast();

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await signUp(email, password);
    } catch (error) {
      console.log("error: " + error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Email already in use or existent account",
        className: "flex flex-inline py-3",
        action: (
          <ToastAction
            className="flex px-3 my-3 border-2 rounded-lg text-sm text-center"
            altText="Retry"
            onClick={() => {
              navigate("/auth/signin");
            }}
          >
            Sign in
          </ToastAction>
        ),
      });
    }
    clearError();
  }, [error]);

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <p className="text-sm text-muted-foreground font-bold tracking-tight">Your Email</p>
            <Input
              data-test-id="signup-email-input"
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(event) => setEmail(event.target.value)}
            />
            <p className="text-sm text-muted-foreground font-bold tracking-tight">Password</p>
            <Input
              data-test-id="signup-email-password"
              id="password"
              placeholder="*********"
              type="password"
              autoCapitalize="none"
              autoComplete="new-password"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(event) => setPassword(event.target.value)}
            />
            <p className="text-sm text-muted-foreground font-bold tracking-tight">Confirm Password</p>
            <Input
              data-test-id="signup-email-confirmpassword"
              id="confirmpassword"
              placeholder="*********"
              type="password"
              autoCapitalize="none"
              autoComplete="new-password"
              autoCorrect="off"
              disabled={isLoading}
              onChange={(event) => setconfirmPassword(event.target.value)}
            />
          </div>
          <div className="flex items-center space-x-2 mt-2">
            <Checkbox
              id="terms-and-privacy"
              checked={isAcceptTerms && isAcceptPrivacyPolicy}
              onCheckedChange={(checked) => {
                setIsAcceptTerms(!!checked);
                setIsAcceptPrivacyPolicy(!!checked);
                setIsDialogTermsChecked(!!checked);
                setIsDialogPrivacyChecked(!!checked);
              }}
            />
            <label className="text-sm text-muted-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pr-1">
              I agree to {""}
              <Dialog>
                <DialogTrigger asChild data-test-id="signup-term-use">
                  <a href="#" className="underline pr-1">
                    Terms of Use {""}
                  </a>
                </DialogTrigger>
                <DialogContent className="max-w-screen-md h-5/6">
                  <DialogHeader>
                    <DialogTitle className="text-2xl ">Terms of Use</DialogTitle>
                    <DialogDescription>Please review our terms of use carefully.</DialogDescription>
                  </DialogHeader>
                  <p>
                    Welcome to Cool Guardian. By using our application, you agree to comply with and be bound by the
                    following terms and conditions. Please review them carefully.
                  </p>
                  <div className="flex flex-col space-y-4 overflow-y-auto">
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Acceptance of Terms</h2>
                      <p>
                        By creating an account and using our application, you agree to these Terms of Service and our
                        Privacy Policy. If you do not agree, please do not use our application.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Account Creation</h2>
                      <p>
                        Users can create accounts to access certain features of our application. You are responsible for
                        maintaining the confidentiality of your account information and for all activities that occur
                        under your account.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">User Content</h2>
                      <p>
                        Users are not permitted to create or upload content to our application. Any attempt to do so
                        will be considered a violation of these Terms of Service.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">In-App Purchases and Subscriptions</h2>
                      <p>
                        We offer in-app purchases and subscription plans. Our subscription plan is priced at $24 and
                        includes a free trial period. By subscribing, you agree to our subscription terms, including
                        automatic renewal unless canceled before the next billing cycle.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Feedback and Suggestions</h2>
                      <p>
                        We welcome feedback and suggestions from our users. By submitting feedback or suggestions, you
                        grant us the right to implement and use them without any obligation to you.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Prohibited Transactions</h2>
                      <p>
                        Users cannot buy goods, items, or services through our application. Only one-time payments for
                        in-app purchases are allowed.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Intellectual Property</h2>
                      <p>
                        All content within our application, including logos, visual designs, trademarks, and other
                        intellectual property, is our exclusive property. Unauthorized use of our intellectual property
                        is strictly prohibited.
                      </p>
                    </div>
                  </div>
                  <DialogFooter className="flex justify-start">
                    <div className="flex w-full space-x-2 items-center justify-start">
                      <Checkbox
                        id="dialog-terms-checkbox"
                        data-test-id="dialog-terms-checkbox"
                        checked={isDialogTermsChecked}
                        onCheckedChange={(checked) => setIsDialogTermsChecked(!!checked)}
                      />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        I agree to these terms and conditions.
                      </label>
                    </div>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
              and {""}
              <Dialog>
                <DialogTrigger asChild data-test-id="signup-privacy-policy">
                  <a href="#" className="underlin pl-1">
                    Privacy Policy. {""}
                  </a>
                </DialogTrigger>
                <DialogContent className="max-w-screen-md h-5/6">
                  <DialogHeader>
                    <DialogTitle className="text-2xl ">Privacy Policy</DialogTitle>
                    <DialogDescription>Please review our privacy policy carefully.</DialogDescription>
                  </DialogHeader>
                  <p>
                    Welcome to Cool Guardian. By using our application, you agree to comply with and be bound by the
                    following terms and conditions. Please review them carefully.
                  </p>
                  <div className="flex flex-col space-y-4 overflow-y-auto">
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Acceptance of Terms</h2>
                      <p>
                        By creating an account and using our application, you agree to these Terms of Service and our
                        Privacy Policy. If you do not agree, please do not use our application.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Account Creation</h2>
                      <p>
                        Users can create accounts to access certain features of our application. You are responsible for
                        maintaining the confidentiality of your account information and for all activities that occur
                        under your account.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">User Content</h2>
                      <p>
                        Users are not permitted to create or upload content to our application. Any attempt to do so
                        will be considered a violation of these Terms of Service.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">In-App PurchaseTermss and Subscriptions</h2>
                      <p>
                        We offer in-app purchases and subscription plans. Our subscription plan is priced at $24 and
                        includes a free trial period. By subscribing, you agree to our subscription terms, including
                        automatic renewal unless canceled before the next billing cycle.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Feedback and Suggestions</h2>
                      <p>
                        We welcome feedback and suggestions from our users. By submitting feedback or suggestions, you
                        grant us the right to implement and use them without any obligation to you.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Prohibited Transactions</h2>
                      <p>
                        Users cannot buy goods, items, or services through our application. Only one-time payments for
                        in-app purchases are allowed.
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <h2 className="font-bold text-xl">Intellectual Property</h2>
                      <p>
                        All content within our application, including logos, visual designs, trademarks, and other
                        intellectual property, is our exclusive property. Unauthorized use of our intellectual property
                        is strictly prohibited.
                      </p>
                    </div>
                  </div>
                  <DialogFooter className="flex justify-start">
                    <div className="flex w-full space-x-2 items-center justify-start">
                      <Checkbox
                        id="dialog-privacy-checkbox"
                        data-test-id="dialog-privacy-checkbox"
                        checked={isDialogPrivacyChecked}
                        onCheckedChange={(checked) => setIsDialogPrivacyChecked(!!checked)}
                      />
                      <label
                        htmlFor="privacy"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        I agree to this privacy policy.
                      </label>
                    </div>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </label>
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <Checkbox
              checked={isAcceptNotificationPartner}
              onCheckedChange={(checked) => setIsAcceptNotificationPartner(!!checked)}
            />
            <label className="text-sm font-normal leading-none text-muted-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              I agree to automatically share alarm events with my installer partner.
            </label>
          </div>
          <Button
            data-test-id="button-signup"
            disabled={isLoading || !isAcceptTerms || !isAcceptPrivacyPolicy}
            type="submit"
          >
            {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
            Create your account
          </Button>
        </div>
      </form>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">Or</span>
        </div>
      </div>
      <Button
        data-test-id="signup-button-google"
        onClick={() => signInWithGoogle()}
        variant="outline"
        type="button"
        disabled={isLoading}
        className="text-foreground"
      >
        {isLoading ? (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <img src={IconGoogle} alt="Google Icon" className="mr-2 h-4 w-4" />
        )}{" "}
        Sign up with Google
      </Button>
    </div>
  );
}
