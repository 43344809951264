import { create } from "zustand";
import { httpApi } from "@/http-api";
import { Customer, InstalledDevice } from "@rtbot-dev/json-schemas";
import { useAuthStore } from "../auth/auth-store";
import { useSensorStore } from "./sensor-store";

type UIState = {
  expandedItems: string[];
  loadingDevices: boolean;
  showMetric: boolean;
  showGauges: boolean;
  isPartner: boolean;
  isRenameOpen: boolean;
  status: string | undefined;
  selectedDevicesTab: "my-devices" | "customer-devices";
};

export type CustomerDevices = {
  customer: Customer;
  devices: InstalledDevice[];
};

type DashboardState = {
  ui: UIState;
  devices: InstalledDevice[];
  customerDevices: CustomerDevices[] | null;
  selectedDevice: InstalledDevice | null;
  isExpanded: boolean;
  operatingMode: "Heating" | "Cooling" | "Unknown";
};

type DashboardActions = {
  loadDevices: () => Promise<void>;
  loadCustomerDevices: () => Promise<void>;
  toggleShowMetric: () => void;
  selectDevice: (deviceId: string) => void;
  setStatus: (status: string | undefined) => void;
  setIsRenameOpen: (open: boolean) => void;
  updateDeviceName: (deviceId: string, newName: string) => Promise<void>;
  toggleSidebar: () => void;
  toggleShowGauges: () => void;
  setDrawerOpen: (isOpen: boolean) => void;
  setOperatingMode: (mode: string) => void;
  setExpandedItems: (expandedItems: string[]) => void;
  setShowGauges: (showGauges: boolean) => void;
  setSelectedDevicesTab: (tab: "my-devices" | "customer-devices") => void;
};

export const useDashboardStore = create<DashboardState & DashboardActions>((set, get) => ({
  ui: {
    expandedItems: [],
    loadingDevices: true,
    showMetric: false,
    showGauges: true,
    isPartner: false,
    isRenameOpen: false,
    status: undefined,
    selectedDevicesTab: "my-devices",
  },
  devices: [],
  customerDevices: null,
  selectedDevice: null,
  isExpanded: false,
  operatingMode: "Unknown",

  setOperatingMode: (mode: "Heating" | "Cooling" | "Unknown") => set({ operatingMode: mode }),

  setExpandedItems: (expandedItems: string[]) =>
    set((state) => ({ ui: { ...state.ui, expandedItems: expandedItems } })),

  toggleShowGauges: () => set((state) => ({ ui: { ...state.ui, showGauges: !state.ui.showGauges } })),

  setShowGauges: (showGauges: boolean) => set((state) => ({ ui: { ...state.ui, showGauges: showGauges } })),

  setSelectedDevicesTab: (tab) => set((state) => ({ ui: { ...state.ui, selectedDevicesTab: tab } })),

  toggleShowMetric: () => set((state) => ({ ui: { ...state.ui, showMetric: !state.ui.showMetric } })),

  setStatus: (status) => set((state) => ({ ui: { ...state.ui, status } })),

  setIsRenameOpen: (open) => set((state) => ({ ui: { ...state.ui, isRenameOpen: open } })),

  selectDevice: (deviceId: string) => {
    const { devices, customerDevices } = get();
    let device = devices.find((d) => d.deviceId === deviceId);

    if (!device && customerDevices) {
      for (const customer of customerDevices) {
        device = customer.devices.find((d) => d.deviceId === deviceId);
        if (device) break;
      }
    }

    if (device) {
      set({ selectedDevice: device });
      useSensorStore.getState().setIsLoading(true);
      if (device.subscribed) {
        useSensorStore.getState().cleanupStreams();
        if (device.remoteKernelIds && device.remoteKernelIds.length > 0) {
          useSensorStore.getState().initializeStreams(device.remoteKernelIds[0]);
        }
      }
    }
  },

  async loadDevices() {
    const { user } = useAuthStore.getState();
    if (!user) return;

    const token = await user.getIdTokenResult();
    const isPartner = token.claims.partner === true;

    set((state) => ({ ui: { ...state.ui, isPartner } }));

    const { data: devices } = await httpApi.getDevices();
    set((state) => ({
      devices,
      ui: { ...state.ui, loadingDevices: false },
    }));

    if (isPartner) {
      await get().loadCustomerDevices();
    }
  },

  async loadCustomerDevices() {
    try {
      const { data: customers } = await httpApi.getCustomers();
      const customerDevices = await Promise.all(
        customers.map(async (customer) => {
          const { data: devices } = await httpApi.getCustomerDevices(customer.id);
          return { customer, devices };
        })
      );
      set({ customerDevices });
    } catch (error) {
      console.error("Error loading customer devices:", error);
    }
  },

  async updateDeviceName(deviceId: string, newName: string) {
    try {
      const { data: updatedDevice } = await httpApi.renameDevice(deviceId, newName);
      set((state) => ({
        devices: state.devices.map((device) => (device.deviceId === deviceId ? updatedDevice : device)),
        selectedDevice: state.selectedDevice?.deviceId === deviceId ? updatedDevice : state.selectedDevice,
        customerDevices: state.customerDevices?.map((customer) => ({
          ...customer,
          devices: customer.devices.map((device) => (device.deviceId === deviceId ? updatedDevice : device)),
        })),
      }));
    } catch (error) {
      console.error("Failed to rename device:", error);
      throw error;
    }
  },

  toggleSidebar: () => set((state) => ({ isExpanded: !state.isExpanded })),
  setDrawerOpen: (isOpen: boolean) => set({ isExpanded: isOpen }),
}));
