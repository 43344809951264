import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { AlertTriangle, CheckCircle2, Thermometer, ArrowUp, ArrowDown } from "lucide-react";
import { useCallback } from "react";

export const SystemStatusCard = ({ device }) => {
  const { operatingMode } = useDashboardStore(useCallback((state) => ({
    operatingMode: state.operatingMode,
  }),[]));

  const getModeIcon = (mode: string) => {
    switch (mode) {
      case "Heating":
        return <ArrowUp className="w-5 h-5 text-red-500" />;
      case "Cooling":
        return <ArrowDown className="w-5 h-5 text-cyan-vivid" />;
      default:
        return <Thermometer className="w-5 h-5 text-gray-500" />;
    }
  };

  const getModeColor = (mode: string) => {
    switch (mode) {
      case "Heating":
        return "text-red-500";
      case "Cooling":
        return "text-cyan-500";
      default:
        return "text-gray-500";
    }
  };

  return (
    <Card
      className={`border-2 ${
        device.active ? "border-cyan-vivid/40 bg-cyan-vivid/5 shadow-lg" : "border-yellow-500 bg-yellow-500/10"
      }`}
    >
      <CardHeader>
        <CardTitle>System Status</CardTitle>
      </CardHeader>
      <CardContent>
        {device.active ? (
          <div className="space-y-4">
            <div className="flex items-center">
              <CheckCircle2 className="w-6 h-6 mr-2" />
              <span>System is operating normally.</span>
            </div>
            <div className="flex items-center">
              {getModeIcon(operatingMode)}
              <span className={`ml-2 ${getModeColor(operatingMode)}`}>Operating Mode: {operatingMode}</span>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <AlertTriangle className="w-6 h-6 mr-2 text-yellow-500" />
            <span>System is currently offline.</span>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
