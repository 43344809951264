import { Button } from "@/components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Check, Download, AlertTriangle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { usePaymentStore } from "../payment-store";
import LayoutPayment from "../layout/LayoutPayment";
import { useCallback, useEffect, useRef, useMemo } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import type { CreateTypes } from "canvas-confetti";
import { Skeleton } from "@/components/ui/skeleton";
import { capitalize } from "../../../helpers";

export default function SuccessPaymentPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");
  const { fetchInvoice, invoice, isLoading, error, selectedPaymentOption } = usePaymentStore(useCallback((state) => ({
    fetchInvoice: state.fetchInvoice,
    invoice: state.invoice,
    isLoading: state.isLoading,
    error: state.error,        
    selectedPaymentOption: state.selectedPaymentOption
  }),[]));
  
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((instance: CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);  
  

  useEffect(() => {
    if (deviceId) {      
      fetchInvoice(deviceId);
      return;     
    } else {
      navigate("/dashboard");
      return;
    }
  }, [deviceId]);

  const invoiceDetails = useMemo(
    () => ({
      refNumber: (invoice?.invoice?.paid && (invoice.invoice.paid === true)) && invoice?.charge?.receiptNumber ? invoice.charge.receiptNumber : "Pending",
      currency: invoice?.charge?.currency ? invoice.charge.currency.toUpperCase() : "N/A",
      paymentTime: invoice?.charge?.createdAt ? new Date(invoice.charge.createdAt * 1000).toLocaleString() : "N/A",
      paymentMethod: invoice?.charge?.paymentMethodType ? capitalize(invoice.charge.paymentMethodType.replaceAll("_"," ")) : "N/A",
      customerEmail: invoice?.invoice?.customerEmail || "N/A",
      amount: invoice?.invoice?.amountPaid ? `$${(invoice.invoice.amountPaid / 100).toFixed(2)}` : "N/A",
      status: (invoice?.invoice?.paid && (invoice.invoice.paid === true)) ? "Paid" : "N/A",
    }),
    [invoice]
  );

  const handleRetry = useCallback(() => {   
    if (deviceId) {      
      fetchInvoice(deviceId);
      return;     
    } else {
      navigate("/dashboard");
      return; 
    }     
  }, [deviceId]);

  return (
    <LayoutPayment>
      <ReactCanvasConfetti
        onInit = {getInstance}
        style={{
          position: "fixed",
          pointerEvents: "none",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 100,
        }}
      />
      <div className="flex justify-center">
        <div className="container max-w-screen-md px-4 md:px-8 items-center bg-transparent dark:bg-transparent">
          <div className="flex flex-col w-full justify-center mt-28">
            <div className="p-6 shadow-xl shadow-cyan-pale bg-cyan-vivid/10 rounded-xl border border-[1px]">
              <div className="space-y-4">
                <div className="flex justify-center">
                  <div className="relative bg-cyan-mostwhite p-2 rounded-full z-10">
                    <div className="bg-cyan-pale p-4 rounded-full">
                      <div className="bg-primary p-2 rounded-full">
                        {isLoading ? (
                          <Skeleton className="h-6 w-6 rounded-full" />
                        ) : error ? (
                          <AlertTriangle className="h-6 w-6 text-red-500" />
                        ) : (
                          <Check className="h-6 w-6 text-white" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="font-bold text-2xl sm:text-4xl text-center text-strong-cyan">
                  {isLoading ? "Processing Payment" : error ? "Payment Error" : "Payment Success"}
                </h2>
                <div className="text-lg font-semibold text-center">
                  {isLoading ? (
                    <Skeleton className="h-6 w-24 mx-auto" />
                  ) : (
                    `$${invoice?.invoice?.amountPaid ? (invoice.invoice.amountPaid / 100).toFixed(2) : selectedPaymentOption.totalPrice}`
                  )}
                </div>
                <p className="text-md text-center text-muted-foreground">
                  {isLoading
                    ? "Please wait while we process your payment..."
                    : error
                    ? "An error occurred while processing your payment."
                    : "Thank you for your payment. Your transaction has been completed successfully."}
                </p>
              </div>
              {(error === null) && (
              <div className="flex flex-col my-4">
                <Separator className="my-4" />
                <div className="divide-y divide-dashed divide-slate-400/25">
                  {Object.entries(invoiceDetails).map(([key, value]) => (
                    <div key={key} className="grid grid-cols-2 p-2">
                      <div className="text-muted-foreground">
                        <p><strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong></p>
                      </div>
                      <div className="text-end justify-self-end">
                        {isLoading ? <Skeleton className="h-4 w-24 ml-auto" /> : <p>{value}</p>}
                      </div>
                    </div>
                  ))}
                </div>
              </div>)}
            </div>
            <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-4 sm:space-y-0 sm:space-x-4">
              <Button
                variant="outline"
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  if (invoice?.receiptPdf) {
                    window.open(invoice.receiptPdf, "_blank");
                  }
                }}
                disabled={isLoading || !(invoice?.receiptPdf)}
              >
                <Download className="mr-2" />
                Get PDF Receipt
              </Button>
              <Button
                className="w-full sm:w-auto rounded-3xl"
                onClick={() => {
                  navigate(`/dashboard?deviceId=${deviceId}`);
                }}
                disabled={isLoading}
              >
                Go to your dashboard
              </Button>
            </div>
            {error && (
              <div className="mt-4 mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded" role="alert">
                <p>{error}</p>
                <Button onClick={handleRetry} className="mt-2 bg-red-500 hover:bg-red-600 text-white">
                  Retry
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </LayoutPayment>
  );
}
