import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import LayoutPayment from "../layout/LayoutPayment";
import { usePaymentStore } from "../payment-store";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons } from "@/components/ui/icons";
import { CheckCircle2 } from "lucide-react";

export const Checkout = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");
  const { clientSecret, stripe, setElements, initiatePayment, selectedPrice, selectedPaymentOption } = usePaymentStore();

  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!deviceId) {
      navigate("/dashboard");
      return;
    }    
  }, [deviceId]);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      navigate("/dashboard");
      return;
    }
    console.log("Creating stripe payment element");
    const elements = stripe.elements({ clientSecret });
    setElements(elements);
    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
    paymentElement.on("change", (event) => {
      setValid(event.complete);
    });
  }, [clientSecret]);

  if (!stripe && !clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <LayoutPayment>
      <div className="w-full mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 flex justify-center mt-24">
            <div className="flex flex-col w-full sm:max-w-md mx-auto">
              <div id="payment-element" className="w-full pb-4">
                <p>
                  Payment methods are dynamically displayed based on customer location, order amount, and
                  currency.&nbsp;
                </p>
              </div>
              <Button
                disabled={!valid || loading}
                onClick={async () => {
                  setLoading(true);
                  let paymentResult = await initiatePayment();
                  if (paymentResult.error) {
                    console.error(paymentResult.error.message);
                    setLoading(false);
                  } else {                                  
                    const url = `/payment/success?deviceId=${deviceId}`;                    
                    setLoading(false);                    
                    navigate(url);
                  }
                }}
              >
                {loading ? <Icons.spinner className="mr-2 h-4 w-4 animate-spin" /> : "Pay"}
              </Button>
            </div>
          </div>
          <div className="h-screen">
            <div className="md:mt-24">
              <Card className="border-none shadow-none px-4">
                <CardHeader>
                  <CardTitle>
                    <div className="flex flex-row">
                      <div className="font-semibold tracking-tight text-xl">
                        Plan {selectedPaymentOption.selectedPlan}
                      </div>
                    </div>
                  </CardTitle>
                  <CardDescription>Monthly plan with renewal required each month</CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  {/* Plan Name and Price */}
                  <div className="py-4 space-y-4">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-row">
                        <p className="font-semi-bold">{selectedPaymentOption.selectedPlanDescription}</p>
                      </div>
                      <span>${selectedPaymentOption.selectedPrice}</span>
                    </div>
                    {/* One-time fee if applicable */}
                    {selectedPrice?.oneTime && (
                      <div className="flex justify-between items-center pt-2 border-t">
                        <div>
                          <h3 className="font-semibold">One-time setup fee</h3>
                        </div>
                        <span>{selectedPaymentOption.selectedPrice}</span>
                      </div>
                    )}
                    {selectedPaymentOption.oneTimePriceId && (
                      <div className="flex flex-row justify-between font-normal text-base">
                        <p>Device</p>
                        <p>${selectedPaymentOption.priceOneTime}</p>
                      </div>
                    )}
                  </div>
                  {/* Total */}
                  <div className="flex justify-between items-center text-lg pt-4 border-t">
                    <div>
                      <h3 className="font-semibold">Total to pay now</h3>
                    </div>
                    <span className="font-semibold text-lg">${selectedPaymentOption.totalPrice}</span>
                  </div>
                  {/* Features */}
                  <div className="pt-8 border-t">
                    <h3 className="font-semibold mb-6">Profits resulting from the subscription selected:</h3>
                    <ul className="space-y-3">
                      {selectedPaymentOption.featureSelected.map((feature, index) => (
                        <li className="flex items-center gap-2" key={index}>
                          <CheckCircle2 className="flex-none text-blue-brand" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </LayoutPayment>
  );
};
