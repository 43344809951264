import { useNavigate } from "react-router-dom";
import { SignUpForm } from "./SignUpForm";
import { AuthLayout } from "../layout/AuthLayout";

//type Props = {}

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <div className="p-2 sm:p-16 md:p-2 lg:p-12 xl:p-20 space-y-4">
        <div className="pt-12 lg:pt-0 flex flex-col space-y-2 text-center">
          <h1 className="text-xl md:text-2xl font-semibold tracking-tight text-foreground">Create an account</h1>
        </div>
        <div className="flex justify-center text-sm font-medium space-x-1 pb-4">
          <h2 className="text-muted-foreground">Not have an account??</h2>
          <label
            data-test-id="signup-label-signin"
            className="underline peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer text-foreground leading-tight"
            onClick={() => navigate("/auth/signin")}
          >
            Sign in
          </label>
        </div>
        <SignUpForm />
      </div>
    </AuthLayout>
  );
};

export default SignUp;
