import { useState } from "react";
import { useStepper } from "@/components/ui/stepper";
import { Button } from "@/components/ui/button";
import { usePartnerStore } from "../../partner-store";
import { httpApi } from "@/http-api";
import { useNavigate } from "react-router-dom";
import { NewCustomerSetup } from "@rtbot-dev/json-schemas";


export const NewCustomerRegistrationForm = () => {

  const navigate = useNavigate();
  const { setCustomerSetup, customerSetup } = usePartnerStore((state) => ({setCustomerSetup: state.setCustomerSetup, customerSetup: state.customerSetup}));  
  const { nextStep } = useStepper();

  const [formData, setFormData] = useState({
    installationId: customerSetup?.installationId || "",
    firstName: customerSetup?.firstName || "",
    lastName: customerSetup?.lastName || "",
    email: customerSetup?.emailAddress || "",
    phone: customerSetup?.phoneNumber || "",
  });

  const [errors, setErrors] = useState({
    installationId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [installationIdNotFound, setInstallationIdNotFound] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const isValidInstallationId = (installationId) => {
    let newErrors = { installationId: "", firstName: errors.firstName, lastName: errors.lastName, email: errors.email, phone: errors.phone };
    let isValid = true;

    if (!installationId.trim()) {
      newErrors.installationId = "Installation ID is required";
      isValid = false;
    }
    else if (!/^[A-Z0-9]{7}$/.test(installationId)) {
      newErrors.installationId = "Installation ID must be 7 characters, capital letters and numbers";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  }

  const isValidFirstName = (firstName) => {
    let newErrors = { installationId: errors.installationId, firstName: "", lastName: errors.lastName, email: errors.email, phone: errors.phone };
    let isValid = true;

    if (!firstName.trim()) {
      newErrors.firstName = "First name is required";
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  }

  const isValidLastName = (lastName) => {
    let newErrors = { installationId: errors.installationId, firstName: errors.firstName, lastName: "", email: errors.email, phone: errors.phone };
    let isValid = true;

    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required";
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  }

  const isValidEmail = (email) => {
    let newErrors = { installationId: errors.installationId, firstName: errors.firstName, lastName: errors.lastName, email: "", phone: errors.phone };
    let isValid = true;

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  }

  const isValidPhone = (phone) => {
    let newErrors = { installationId: errors.installationId, firstName: errors.firstName, lastName: errors.lastName, email: errors.email, phone: "" };
    let isValid = true;

    if (!phone.trim()) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(phone)) {
      newErrors.phone = "Invalid phone number (10 digits required)";
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  }

  const isValidForm = () => {
    let newErrors = { installationId: "", firstName: "", lastName: "", email: "", phone: "" };
    let isValid = true;

    if (!formData.installationId.trim()) {
      newErrors.installationId = "Installation ID is required";
      isValid = false;
    }else if (!/^[A-Z0-9]{7}$/.test(formData.installationId)) {
      newErrors.installationId = "Installation ID must be 7 characters, capital letters and numbers";
      isValid = false;
    }
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
      isValid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number (10 digits required)";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name == "installationId") isValidInstallationId(e.target.value);
    else if (e.target.name == "firstName") isValidFirstName(e.target.value);
    else if (e.target.name == "lastName") isValidLastName(e.target.value);
    else if (e.target.name == "email") isValidEmail(e.target.value);
    else if (e.target.name == "phone") isValidPhone(e.target.value);
  };

  const saveCustomer = async () => {
    try {
      setInstallationIdNotFound(null);
      if (isValidForm()) {
        setIsLoading(true);
        const response: boolean = (await httpApi.partnerValidateInstallationId(formData.installationId)).data;
        setIsLoading(false);
        if (response) {          
          let customerSetupData: Omit<NewCustomerSetup, "monitoring">  = {
            installationId: formData.installationId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            emailAddress: formData.email,
            phoneNumber: formData.phone
          };
          setCustomerSetup(customerSetupData);
          nextStep();
        } else {
          if (formData.installationId) {
            setInstallationIdNotFound(`Installation ID ${formData.installationId} not found in our records`);
          } else {
            setInstallationIdNotFound(`Installation ID not found in our records`);
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      if (formData.installationId) {
        setInstallationIdNotFound(`Error ocurred while retrieving Installation ID ${formData.installationId}`);
      } else {
        setInstallationIdNotFound(`Error ocurred while retrieving Installation ID`);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center space-y-3">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary mx-auto"></div>
          <div>Saving customer data...</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full p-8 sm:max-w-xl">

       {(installationIdNotFound != null) && 
        <div className="text-center text-red-500 text-sm bg-red-100 border-2 border-red-500 rounded-lg p-4 mb-5">
          <p>{installationIdNotFound}</p>
        </div>}

          {/* Installation ID */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Installation ID*</label>
          <div className="py-5">
            <input
              type="text"
              name="installationId"
              maxLength={7}
              value={formData.installationId}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter Installation ID"
            />
            {errors.installationId && <p className="text-red-500 text-sm">{errors.installationId}</p>}
          </div>
        </div>

        {/* First Name */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">First Name*</label>
          <div className="py-5">
            <input
              type="text"
              name="firstName"
              maxLength={20}
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter First Name"
            />
            {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
          </div>  
        </div>

        {/* Last Name */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Last Name*</label>
          <div className="py-5">
            <input
              type="text"
              name="lastName"
              maxLength={25}
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter Last Name"
            />
            {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
          </div>
        </div>

        {/* Email Address */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Email Address*</label>
          <div className="py-5">
            <input
              type="email"
              name="email"
              maxLength={35}
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter Email Address"
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>  
        </div>

        {/* Phone Number */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Phone Number*</label>
          <div className="py-5">
            <input
              type="tel"
              name="phone"
              maxLength={10}
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter Phone Number"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>  
        </div>
          
          <div className="flex flex-col w-full gap-2 mt-8">
            <Button              
              onClick={() => {
                saveCustomer();
              }}
              size="sm"
              variant="default"
              className="w-full"
            >
              Next
            </Button>
            <div className="relative py-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-b border-gray-300"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-sm text-gray-500">or</span>
              </div>
            </div>
            <Button onClick={() => navigate("/dashboard/")} size="sm" variant="secondary">
              Back
            </Button>            
          </div>
          
        </div>
      </div>
    </>
  );
};
