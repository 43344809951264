import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LucideIcon, ArrowUpIcon, ArrowDownIcon, CalendarIcon } from "lucide-react";

interface StatisticCardProps {
  title: string;
  value: number;
  unit: string;
  icon: LucideIcon;
  peak?: number;
  showPeak?: boolean;
  pctChange?: number | null;
  pctChangePeriod?: string;
  period?: string;
  periodType?: "daily" | "weekly" | "monthly";
}

const ComparisonIndicator = ({ value, period }: { value: number | null; period: string }) => {
  if (value === null) return null;

  const isPositive = value > 0;
  const ComparisonIcon = isPositive ? ArrowUpIcon : ArrowDownIcon;

  return (
    <p className="text-xs tracking-wide flex items-center gap-1">
      <span
        className={`border rounded-full p-[1px] ${
          isPositive ? "border-cyan-vivid/50 bg-cyan-vivid/10" : "border-red-brigth/50 bg-red-brigth/10"
        }`}
      >
        <ComparisonIcon className={`h-3 w-3 ${isPositive ? "text-cyan-vivid" : "text-red-brigth"}`} />
      </span>
      <span>
        {Math.abs(value).toFixed(1)}% vs previous {period}
      </span>
    </p>
  );
};

const formatPeriodLabel = (
  period: string,
  periodType: "daily" | "weekly" | "monthly"
): { label: string; dates: string } => {
  if (periodType === "daily") {
    // Format: "2024-11-08"
    const date = new Date(period);
    return {
      label: "Day",
      dates: date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }),
    };
  } else if (periodType === "weekly") {
    // Format: "2024-W44"
    const [year, week] = period.split("-W");
    return {
      label: "Week",
      dates: `Week ${week}, ${year}`,
    };
  } else if (periodType === "monthly") {
    // Format: "2024-9"
    const [year, month] = period.split("-");
    const monthName = new Date(parseInt(year), parseInt(month) - 1, 1).toLocaleDateString("en-US", { month: "long" });
    return {
      label: "Month",
      dates: `${monthName} ${year}`,
    };
  }

  return { label: "Period", dates: period };
};

export const StatisticCard = ({
  title,
  value,
  unit,
  icon: Icon,
  peak,
  showPeak,
  pctChange,
  pctChangePeriod = "period",
  period,
  periodType = "daily",
}: StatisticCardProps) => {
  const periodInfo = period ? formatPeriodLabel(period, periodType) : null;

  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="grid grid-cols-[1fr_auto] items-start gap-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          <Icon className="h-4 w-4 text-muted-foreground" />
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-1.5">
          <div className="text-2xl font-bold">
            {typeof value === "number" ? value.toFixed(1) : value} {unit}
          </div>

          {periodInfo && (
            <div className="flex items-center gap-1 text-xs text-muted-foreground border-t border-gray-100 pt-1 mt-1">
              <CalendarIcon className="h-3 w-3" />
              <span className="font-medium">{periodInfo.label}:</span> {periodInfo.dates}
            </div>
          )}

          {pctChange !== undefined && (
            <div className="space-y-1 mt-1">
              <ComparisonIndicator value={pctChange} period={pctChangePeriod} />
            </div>
          )}

          {showPeak && (
            <div className="text-xs text-muted-foreground">
              Peak: {peak?.toFixed(1)} {unit}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
