import { EquipmentSchema, Equipment, NewCustomerSetup } from "@rtbot-dev/json-schemas";
import { useState } from "react";
import { useStepper } from "@/components/ui/stepper";
import { Button } from "@/components/ui/button";
import { usePartnerStore } from "../../partner-store";
import { capitalize } from "../../../../helpers";
import { httpApi } from "@/http-api";

export const NewEquipmentRegistrationForm = () => {  
  
  const { deviceSetup, setDeviceSetup, customerSetup, setCustomerSetup, setCustomerEmail } = usePartnerStore((state) => state);

  const { prevStep, nextStep } = useStepper();  

  const [formData, setFormData] = useState({
    refrigerant: deviceSetup?.refrigerant ||  EquipmentSchema.properties.refrigerant.default,
    brand: deviceSetup?.brand || EquipmentSchema.properties.brand.default,
    tonnage: deviceSetup?.tonnage || EquipmentSchema.properties.tonnage.default,
    heatPump: (deviceSetup?.heatPump && deviceSetup.heatPump === true) ? "yes" : "no",
    efficiency: deviceSetup?.efficiency || EquipmentSchema.properties.efficiency.default,
    packagedUnit: (deviceSetup?.packagedUnit && deviceSetup.packagedUnit === true) ? "yes" : "no",
    meteringDevice: deviceSetup?.meteringDevice || EquipmentSchema.properties.meteringDevice.default,
    voltage: deviceSetup?.voltage || EquipmentSchema.properties.voltage.default,
    wattage: deviceSetup?.wattage ? deviceSetup.wattage.toString() : "",
  });

  const [errors, setErrors] = useState({    
    wattage: "",  
  });

  const [registryError, setRegistryError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const isValidWattage = (wattage) => {
    let newErrors = { wattage: "" };
    let isValid = true;

   
    if (!wattage.trim()) {
      newErrors.wattage = "Consumption is required";
      isValid = false;
    }
    else if(isNaN(Number(wattage)) || Number(wattage) <= 0 || 
    !Number.isInteger(Number(wattage)))
    {
      newErrors.wattage = "Consumption must be a valid integer greater than 0";
      isValid = false;
    }    

    setErrors(newErrors);
    return isValid;
  };

  const isValidForm = () => {
    let newErrors = { wattage: "" };
    let isValid = true;
   
    if (!formData.wattage.trim()) {
      newErrors.wattage = "Consumption is required";
      isValid = false;
    }
    else if(isNaN(Number(formData.wattage)) || Number(formData.wattage) <= 0 || 
    !Number.isInteger(Number(formData.wattage)))
    {
      newErrors.wattage = "Consumption must be a valid integer greater than 0";
      isValid = false;
    }    

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement| HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name == "wattage") isValidWattage(e.target.value);
  };



  const installDevice = async () => {
    try {
      setRegistryError(null);
      if (isValidForm()) {
        const deviceSetupData: Equipment = {
          brand: formData.brand,
          tonnage: formData.tonnage,          
          refrigerant: formData.refrigerant,
          installedAt: new Date(),
          heatPump: (formData.heatPump === "yes") ? true : false,
          efficiency: formData.efficiency,
          packagedUnit: (formData.packagedUnit === "yes") ? true : false,
          meteringDevice: formData.meteringDevice,
          wattage: parseInt(formData.wattage),
          voltage: formData.voltage,
        }
        setDeviceSetup(deviceSetupData);
        const newCustomerSetup: NewCustomerSetup = {
          ...customerSetup,
          monitoring: deviceSetupData,
        };
        console.log(newCustomerSetup);
        setIsLoading(true);
        (await httpApi.partnerStartSetup(newCustomerSetup)).data;       
        setIsLoading(false);
        setCustomerEmail(customerSetup.emailAddress);
        setCustomerSetup(null);
        setDeviceSetup(null)
        nextStep();
      }
    } catch(e) {
      setRegistryError("Error ocurred while installing device");
      setIsLoading(false);
    }
  };

  const goBack = () => {
      setRegistryError(null);
      prevStep();   
  };

  const refrigerants: string[] = EquipmentSchema.properties.refrigerant.enum;

  const brands: string[] = EquipmentSchema.properties.brand.enum;

  const tonnages: string[] = EquipmentSchema.properties.tonnage.enum;

  const efficiencies = (EquipmentSchema.properties.efficiency.enum).map((e:any) => (
    {
      name: capitalize(e.replaceAll("-"," ")),
      value: e
    }
  ));

 const meteringDevices: string[] = EquipmentSchema.properties.meteringDevice.enum;

 const voltages: string[] = EquipmentSchema.properties.voltage.enum;

 if (isLoading) {
  return (
    <div className="flex items-center justify-center h-64">
      <div className="text-center space-y-3">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary mx-auto"></div>
        <div>Installing device...</div>
      </div>
    </div>
  );
}

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full p-8 sm:max-w-xl">

        {(registryError != null) && 
        <div className="text-center text-red-500 text-sm bg-red-100 border-2 border-red-500 rounded-lg p-4 mb-5">
          <p>{registryError}</p>
        </div>}

          {/* Refrigerant Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Refrigerant*</label>
            <div className="py-5">
              <select
                name="refrigerant"
                value={formData.refrigerant}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                {refrigerants.map((ref) => (
                  <option key={ref} value={ref}>
                    {ref}
                  </option>
                ))}
              </select>
            </div>  
          </div>

          {/* Brand Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Brand*</label>
            <div className="py-5">
              <select
                name="brand"
                value={formData.brand}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                {brands.map((ref) => (
                  <option key={ref} value={ref}>
                    {ref}
                  </option>
                ))}
              </select>
            </div>  
          </div>

          {/* Tonnage Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Tonnage*</label>
            <div className="py-5">
              <select
                name="tonnage"
                value={formData.tonnage}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                {tonnages.map((ref) => (
                  <option key={ref} value={ref}>
                    {ref}
                  </option>
                ))}
              </select>
            </div>  
          </div>

          {/* Heatpump Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Heat pump*</label>
            <div className="py-5">
              <select
                name="heatpump"
                value={formData.heatPump}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
                
              </select>
            </div>  
          </div>

          {/* Efficency Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Efficiency*</label>
            <div className="py-5">
              <select
                name="efficiency"
                value={formData.efficiency}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                {efficiencies.map((ref) => (
                  <option key={ref.value} value={ref.value}>
                    {ref.name}
                  </option>
                ))}
                
              </select>
            </div>  
          </div>

          {/* Packaged Unit Dropdown */}
          <div className="flex flex-col">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Packaged Unit*</label>
            <div className="py-5">
              <select
                name="packagedUnit"
                value={formData.packagedUnit}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
                
              </select>
            </div>  
          </div>

          {/* Metering Device Dropdown */}
          <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Metering Device*</label>
          <div className="py-5">
            <select
              name="meteringDevice"
              value={formData.meteringDevice}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1"
            >
              {meteringDevices.map((ref) => (
                <option key={ref} value={ref}>
                  {ref}
                </option>
              ))}
            </select>
          </div>  
        </div>

        {/* Voltage Dropdown */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Voltage*</label>
          <div className="py-5">
            <select
              name="voltage"
              value={formData.voltage}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1"
            >
              {voltages.map((ref) => (
                <option key={ref} value={ref}>
                  {ref}
                </option>
              ))}
            </select>
          </div>  
        </div>

        {/* Phone Number */}
        <div className="flex flex-col">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 pb-1">Consumption*</label>
          <div className="py-5">
            <input
              type="text"             
              name="wattage"
              maxLength={6}
              value={formData.wattage}
              onChange={handleChange}
              className="w-full p-2 border rounded mt-1 bg-gray-200"
              placeholder="Enter consumption in kwh"
            />
            {errors.wattage && <p className="text-red-500 text-sm">{errors.wattage}</p>}
          </div>  
        </div>
          
          <div className="flex flex-col w-full gap-2 mt-8">
            <Button type="button" onClick={async () => await installDevice()} size="sm" variant="default">              
              Submit
            </Button>
            <div className="relative py-4">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-b border-gray-300"></div>
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-4 text-sm text-gray-500">or</span>
              </div>
            </div>
            <Button onClick={() => goBack()} size="sm" variant="secondary">
              Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
