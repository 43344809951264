import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, Download } from "lucide-react";
import {
  useBillingStore,
  useDeviceBillings, 
  useBillingLoading,
  useBillingError,
} from "./billing-store";
import { format } from "date-fns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { PaymentMethodManager } from "./PaymentMethodManager";
import { LoadingIndicator } from "./LoadingIndicator";
import { SubscriptionRemoveDialog } from "./SubscriptionRemoveDialog";

export const BillingPanel = () => {
  const { loadDeviceBillings, downloadInvoice, cancelSubscription } = useBillingStore();
  const isCancelSubscriptionOpen = useBillingStore((state) => state.isCancelSubscriptionOpen);
  const setIsCancelSubscriptionOpen = useBillingStore((state) => state.setIsCancelSubscriptionOpen);
  const deviceBillings = useDeviceBillings();  
  const isLoading = useBillingLoading();
  const error = useBillingError();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);

  useEffect(() => {
    loadDeviceBillings();
  }, [loadDeviceBillings]);

  useEffect(() => {
    if (deviceBillings.length > 0 && !selectedDeviceId) {
      const defaultDevice = deviceBillings.find((billing) => billing.device?.deviceId);
      setSelectedDeviceId(defaultDevice?.device?.deviceId || null);
    }
  }, [deviceBillings, selectedDeviceId]);

  const selectedDeviceBilling = deviceBillings.find((billing) => billing.device?.deviceId === selectedDeviceId);
  const activeSubscription = selectedDeviceBilling?.subcriptions.find((sub) => (sub.current == true));
  const subscriptions = selectedDeviceBilling?.subcriptions || [];
  const totalPages = Math.ceil(subscriptions.length / itemsPerPage);
  const paginatedSubscriptions = subscriptions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (value: string) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (deviceBillings.length === 0) {
    return <div className="text-center p-4">No devices found.</div>;
  }

  if (!selectedDeviceBilling) {
    return <div className="text-center p-4">No billing information available for the selected device.</div>;
  }

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 gap-8">
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Billing History</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex sm:flex-row justify-between items-start sm:items-center mb-8">
              
              <Select value={selectedDeviceId || ""} onValueChange={(value) => setSelectedDeviceId(value)}>
                <SelectTrigger className="w-[200px]">
                  <SelectValue>
                    {deviceBillings.find((billing) => billing.device?.deviceId === selectedDeviceId)?.device?.name ||
                      "Select a device"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {deviceBillings.map((billing) => (
                    <SelectItem key={billing.device?.deviceId} value={billing.device?.deviceId || ""}>
                      {billing.device?.name || "Unknown Device"}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              
              <div className="flex items-center space-x-2">
                <p className="text-sm text-muted-foreground hidden lg:block">Show</p>
                <Select value={itemsPerPage.toString()} onValueChange={handleItemsPerPageChange}>
                  <SelectTrigger className="w-[60px] sm:w-[70px]">
                    <SelectValue placeholder={itemsPerPage} />
                  </SelectTrigger>
                  <SelectContent>
                    {[5, 10, 20, 50].map((number) => (
                      <SelectItem key={number} value={number.toString()}>
                        {number}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>                
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full min-w-[250px]">
                <thead>
                  <tr className="text-xs sm:text-sm font-medium text-muted-foreground border-b text-center">
                    <th className="w-1/5 pb-2 text-left">Date</th>
                    <th className="w-1/5 pb-2 text-left hidden lg:table-cell">Plan</th>
                    <th className="w-1/5 pb-2">Amount</th>
                    <th className="w-1/5 pb-2 hidden lg:table-cell">Status</th>
                    <th className="w-1/5 pb-2">Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedSubscriptions.map((subscription) =>
                    subscription.invoices.map((invoice) => (
                      <tr
                        key={invoice.invoice?.id}
                        className="border-b last:border-b-0 hover:bg-muted/50 transition-colors duration-200"
                      >
                        <td className="py-4 text-xs sm:text-sm text-left">
                          {invoice.invoice?.createdAt
                            ? new Date(invoice.invoice.createdAt * 1000).toLocaleDateString("en-US")
                            : "N/A"}
                        </td>
                        <td className="py-4 text-xs sm:text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px] hidden lg:table-cell">
                          {subscription.subscriptionName || "N/A"}
                        </td>
                        <td className="py-4 text-xs sm:text-sm text-center">
                          {invoice.invoice?.amountDue
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: invoice.invoice.currency,
                              }).format(invoice.invoice.amountDue / 100)
                            : "N/A"}
                        </td>
                        <td className="py-4 text-xs sm:text-sm text-center hidden lg:table-cell">
                          {invoice.invoice?.paid ? "Paid" : "Unpaid"}
                        </td>
                        <td className="py-4 text-center">
                          <Button
                            variant="ghost"
                            size="sm"
                            className="hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
                            onClick={() =>
                              selectedDeviceId &&
                              invoice.invoice?.id &&
                              downloadInvoice(selectedDeviceId, subscription.subscription?.id || "", invoice.invoice.id)
                            }
                            disabled={!invoice.receiptPdf}
                          >
                            <Download className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                            <span className="text-xs sm:text-sm">Download</span>
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="mt-6 flex justify-center items-center space-x-1">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="transition-colors duration-200"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                ) {
                  return (
                    <Button
                      key={pageNumber}
                      variant={currentPage === pageNumber ? "default" : "outline"}
                      size="sm"
                      onClick={() => setCurrentPage(pageNumber)}
                      className="hidden sm:inline-flex transition-colors duration-200 min-w-[2.5rem]"
                    >
                      {pageNumber}
                    </Button>
                  );
                } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                  return (
                    <Button
                      key={pageNumber}
                      variant="outline"
                      size="sm"
                      disabled
                      className="hidden sm:inline-flex transition-colors duration-200"
                    >
                      ...
                    </Button>
                  );
                }
                return null;
              })}
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
                className="transition-colors duration-200"
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>
        <div className="space-y-8 lg:space-y-6">
          <Card className="bg-cyan-vivid text-cyan-900 text-primary-foreground">
            <CardHeader>
              <CardTitle className="text-lg sm:text-xl font-semibold">Your Plan</CardTitle>
            </CardHeader>
            <CardContent>
              {selectedDeviceId && activeSubscription ? (
                <>
                  <h3 className="text-1xl md:text-2xl font-bold mb-2">{activeSubscription.subscriptionName}</h3>
                  <p className="text-xs sm:text-sm opacity-90 mb-6">
                    {activeSubscription.subscription.currentPeriodEnd
                      ? `Renews on ${format(activeSubscription.subscription.currentPeriodEnd * 1000, "MMM. dd, yyyy")}`
                      : "No renewal date available"}
                  </p>
                  <Button
                    variant="secondary"
                    className="w-full hover:bg-primary-foreground hover:text-primary transition-colors duration-200"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCancelSubscriptionOpen(true);
                    }}
                  >
                    <span className="text-xs sm:text-sm">Cancel subscription</span>
                  </Button>
                  <SubscriptionRemoveDialog
                    subscriptionId={activeSubscription.subscription.id}
                    deviceId={selectedDeviceId}
                    open={isCancelSubscriptionOpen}
                    onOpenChange={setIsCancelSubscriptionOpen}
                  />
                </>
              ) : (
                <p>No plan information available for the selected device.</p>
              )}
            </CardContent>
          </Card>

          <PaymentMethodManager />
        </div>
      </div>
    </div>
  );
};
