import { Link } from "react-router-dom";
import { PasswordResetLayout } from "./PasswordResetLayout";

export const SetNewPasswordSuccess = () => {
  return (
    <PasswordResetLayout>
      <div className="mx-auto flex w-full flex-col justify-center space-y-3 sm:space-y-6">
        <div className="flex flex-col text-center">
          <h1
            data-test-id="password-reset-success-title"
            className="text-xl md:text-2xl font-semibold tracking-tight text-foreground"
          >
            Reset Password
          </h1>
          <p
            data-test-id="password-reset-success-message"
            className="text:lg px-2 text-muted-foreground text-center text-indigo-500 pt-6 pb-3 sm:pt-10 sm:text-xl"
          >
            Your password was successfully updated.
          </p>
        </div>
        <p className="text-sm text-center text-muted-foreground text-indigo-500">
          Go to{" "}
          <Link
            data-test-id="sign-in-link"
            to="/auth/signin"
            className="underline underline-offset-4 font-semibold  hover:text-primary font-semibold text-lg ml-1"
          >
            Sign In
          </Link>{" "}
        </p>
      </div>
    </PasswordResetLayout>
  );
};
