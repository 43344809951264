import React, { useCallback, useState, useEffect } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Joystick, SettingsIcon, CreditCard, LogOut, ChevronRight, Menu, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthStore } from "../../auth/auth-store";
import { cn } from "@/components/utils";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { DevicesSection } from "../device/DevicesSection";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import WhiteLogo from "@/assets/svg/Color-logo-no-background.svg";
import YellowLogo from "@/assets/Yellow-cicle-point.png";

export const DashboardSidebar = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");  
  const [searchTerm, setSearchTerm] = useState("");  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const {
    expandedItems,
    isExpanded,
    devices,
    customerDevices,
    selectedDevice,
    isPartner,  
    selectedDevicesTab,
    showGauges
  } = useDashboardStore(
    useCallback(
      (state) => ({
        showGauges: state.ui.showGauges,
        isExpanded: state.isExpanded,        
        devices: state.devices,
        customerDevices: state.customerDevices,
        selectedDevice: state.selectedDevice,
        isPartner: state.ui.isPartner,
        expandedItems: state.ui.expandedItems,
        showMetric: state.ui.showMetric,
        selectedDevicesTab: state.ui.selectedDevicesTab,
      }),
      []
    )
  );

  const {   setSelectedDevicesTab, toggleSidebar, loadDevices, selectDevice, setDrawerOpen, setShowGauges } = useDashboardStore(
    useCallback(
      (state) => ({ 
        setShowGauges: state.setShowGauges,      
        setSelectedDevicesTab: state.setSelectedDevicesTab,
        loadDevices: state.loadDevices,
        selectDevice: state.selectDevice,        
        setDrawerOpen: state.setDrawerOpen,
        toggleSidebar: state.toggleSidebar,
      }),
      []
    )
  );

  const { user, signOut } = useAuthStore( useCallback((state) => 
    ({signOut: state.signOut, 
      user: state.user
    }),
    []
  ));

  useEffect(() => {   
    loadDevices().then(() => {

      const devices  = useDashboardStore.getState().devices;
      const customerDevices  = useDashboardStore.getState().customerDevices;           
     
      if (deviceId) {
        const isCustomerDevice = (devices && devices.find( (device) => device.deviceId === deviceId));
        const isPartnerDevice = (customerDevices && customerDevices.reduce((acc,customerDevice) => acc || (customerDevice.devices.findIndex((device) => device.deviceId === deviceId) >=0), false));
        if (isCustomerDevice || isPartnerDevice) {         
          selectDevice(deviceId);
          setDrawerOpen(true);
          let expandedItems: string[] = useDashboardStore.getState().ui.expandedItems;
          if (!expandedItems.includes("devices")) {
            expandedItems.push("devices");
            useDashboardStore.setState((state) => ({ ui: { ...state.ui, expandedItems:expandedItems } }));
          }
          let isPartner = useDashboardStore.getState().ui.isPartner;
          if (isPartner && isPartnerDevice) {
            useDashboardStore.setState((state) => ({ ui: { ...state.ui, selectedDevicesTab: "customer-devices" } }));
          } else if (isPartner && isCustomerDevice)  {
            useDashboardStore.setState((state) => ({ ui: { ...state.ui, selectedDevicesTab: "my-devices" } }));
          }
        }
        return;
      }   
     
    });
  }, [deviceId]);

  useEffect(() => {    
    if (!showGauges) { 
      setShowGauges(true);
    }
    if (!isExpanded) {
      useDashboardStore.setState((state) => ({ ui: { ...state.ui, expandedItems:[] } }));   
    }
  }, [isExpanded]);

  const handleDeviceSelect = (device) => {
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }    
    navigate(`/dashboard?deviceId=${device.deviceId}`);    
  };  

  const handleAccordionChange = (value: string[]) => {    
    useDashboardStore.setState((state) => ({ ui: { ...state.ui, expandedItems:value } }));    
  };

  const handleToggleSidebar = () => {
    const isExpanded = useDashboardStore.getState().isExpanded;
    const expandedItems = useDashboardStore.getState().ui.expandedItems;
    if (isExpanded && isExpanded === true && expandedItems.length > 0) {
      useDashboardStore.setState((state) => ({ ui: { ...state.ui, expandedItems:[] } }));      
      setTimeout(() => toggleSidebar(), 300);
    } else {
      toggleSidebar();
    }
  };

  const SidebarContent = () => (
    <div className="flex h-full flex-col bg-cyan-verydark shadow-none backdrop-blur text-cyan-mostwhite">
      <div className="px-4 py-4 mt-2">
        <h2
          className={cn("text-lg font-semibold hover:cursor-pointer", !isExpanded && "hidden")}
          onClick={() => navigate("/dashboard")}
        >
          <img src={WhiteLogo} alt="WhiteLogo" className="h-10 object-contain" />
        </h2>
      </div>

      <ScrollArea className="flex-grow px-2">
        <Accordion type="multiple" value={expandedItems} onValueChange={handleAccordionChange} className="w-[99%]">
          <AccordionItem value="devices" className="border-b-[1px] border-slate-50 ">
            <AccordionTrigger
              disabled={!isExpanded}
              className="my-2 p-2 hover:bg-accent hover:text-accent-foreground rounded-lg hover:no-underline"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-2">
                  <Joystick className="h-4 w-4 flex-shrink-0" />
                  <span className={cn("truncate", !isExpanded && "hidden")}>Devices</span>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="bg-Black/10 p-2 rounded-lg">
              <DevicesSection
                devices={devices}
                customerDevices={customerDevices}
                selectedDevice={selectedDevice}
                onDeviceSelect={handleDeviceSelect}
                isPartner={isPartner}
                selectedTab={selectedDevicesTab}
                onTabChange={setSelectedDevicesTab}
              />
            </AccordionContent>
          </AccordionItem>
          {/*
          <AccordionItem value="settings">
            <AccordionTrigger
              disabled={!isExpanded}
              className="my-2 p-2 hover:bg-accent hover:text-accent-foreground rounded-lg hover:no-underline"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-2">
                  <SettingsIcon className="h-4 w-4 flex-shrink-0" />
                  <span className={cn("truncate", !isExpanded && "hidden")}>Settings</span>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="bg-Black/10 p-2 rounded-lg">
              <div className="space-y-6 px-4 pb-2">
                <div className="space-y-4">
                  <h4 className="text-sm font-medium">Units</h4>
                  <RadioGroup
                    defaultValue={showMetric ? "metric" : "imperial"}
                    onValueChange={(value) => toggleShowMetric()}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="metric" id="metric" />
                      <Label htmlFor="metric">Metric</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="imperial" id="imperial" />
                      <Label htmlFor="imperial">Imperial</Label>
                    </div>
                  </RadioGroup>
                </div>

                <div className="space-y-4">
                  <h4 className="text-sm font-medium">Display</h4>
                  <div className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Checkbox id="showOffline" />
                      <Label htmlFor="showOffline">Show offline devices</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox id="showUnsubscribed" />
                      <Label htmlFor="showUnsubscribed">Show unsubscribed devices</Label>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>*/}

          {!isPartner && (devices && devices.length > 0) && (
          <AccordionItem value="billing" onClick={() => navigate("/dashboard/billing")}>
            <AccordionTrigger
              showHandler={false}
              disabled={!isExpanded}
              className="my-2 p-2 hover:bg-accent hover:text-accent-foreground rounded-lg hover:no-underline"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center space-x-2">
                  <CreditCard className="h-4 w-4 flex-shrink-0" />
                  <span className={cn("truncate", !isExpanded && "hidden")}>Subscriptions</span>
                </div>
              </div>
            </AccordionTrigger>
          </AccordionItem>          
          )}
          {isPartner && (
          <AccordionItem value="add-device" onClick={() => navigate("/partner/new-registration")}>
          <AccordionTrigger
            showHandler={false}
            disabled={!isExpanded}
            className="my-2 p-2 hover:bg-accent hover:text-accent-foreground rounded-lg hover:no-underline"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center space-x-2">
                <Plus className="h-4 w-4 flex-shrink-0" />
                <span className={cn("truncate", !isExpanded && "hidden")}>Add New Device</span>
              </div>
            </div>
          </AccordionTrigger>          
        </AccordionItem>)}
        </Accordion>
      </ScrollArea>

      {/* User Profile Section */}
      <div className="mt-auto p-4">
        <div className="flex items-center gap-3">
          <Avatar className="bg-blue-500">
            <AvatarImage src={user?.photoURL || "/placeholder.svg"} />
            <AvatarFallback className="bg-blue-500">{user?.displayName ? user.displayName[0].toUpperCase() : (user?.email?.[0].toLocaleUpperCase() || "U")}</AvatarFallback>
          </Avatar>
          <div className={cn("flex flex-col overflow-hidden", !isExpanded && "hidden")}>
            <span className="font-medium truncate">{user?.displayName || user?.email}</span>
            {user?.displayName && <span className="text-sm text-muted-foreground truncate">{user?.email}</span>}
          </div>
        </div>
        <Button
          data-test-id="sidebar-button-logout"
          variant="ghost"
          className="w-full justify-start mt-2 px-0"
          onClick={async () => {
            await signOut();
            navigate("/auth/signin");
          }}
        >
          <LogOut className="h-5 w-5 mr-2" />
          <span className={cn(!isExpanded && "hidden")}>Log out</span>
        </Button>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <>
        <TooltipProvider>
          <div className="fixed top-0 left-0 right-0 h-16 bg-cyan-verydark border-b z-50 flex items-center px-4">
            <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
              <SheetTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Menu className="h-6 w-6 text-White" />
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="p-0 w-[calc(100%-10px)] max-w-[390px] text-White bg-cyan-verydark">
                <SidebarContent />
              </SheetContent>
              {isMobileMenuOpen && (
                <div className="fixed inset-0 bg-transparent z-40" onClick={() => setIsMobileMenuOpen(false)} />
              )}
            </Sheet>
            <div className="ml-4 flex-grow">
              <div className="flex items-center hover:cursor-pointer" onClick={() => navigate("/dashboard")}>
                <img src={WhiteLogo} alt="WhiteLogo" className="h-8 object-contain" />
              </div>
            </div>
          </div>
          <div className="h-16" /> {/* Spacer to push content below the fixed header */}
        </TooltipProvider>
      </>
    );
  }

  return (
    <>
      <TooltipProvider>
        <div
          className={cn(
            "fixed left-0 top-0 z-40 h-screen bg-cyan-dark/70 border-r transition-all duration-300",
            isExpanded ? "w-[390px]" : "w-16"
          )}
        >
          <SidebarContent />
        </div>

        {/* Collapsed Sidebar Icons with Tooltips */}
        {!isExpanded && (
          <div className="fixed left-0 top-0 z-40 bg-cyan-dark text-cyan-mostwhite h-screen w-16 border-r py-4 flex flex-col justify-between">
            <div className="flex flex-col items-center space-y-4">
              <div className="mt-2">
                <img src={YellowLogo} alt="YellowLogo" className="h-10 object-contain" />
              </div>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="w-12 h-12"
                    onClick={() => navigate("/dashboard")}
                  >
                    <Joystick className="h-6 w-6" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Devices</TooltipContent>
              </Tooltip>
              {/*
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="w-12 h-12"
                    onClick={() => handleAccordionChange(["settings"])}
                  >
                    <SettingsIcon className="h-6 w-6" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Settings</TooltipContent>
              </Tooltip>
              */}
              {!isPartner && (<Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="w-12 h-12"
                    onClick={() => navigate("/dashboard/billing")}
                  >
                    <CreditCard className="h-6 w-6" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Billing</TooltipContent>
              </Tooltip>)}
            </div>

            <div className="flex flex-col items-center space-y-4 mb-4">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Avatar className="bg-blue-500">
                    <AvatarImage src={user?.photoURL || "/placeholder.svg"} />
                    <AvatarFallback className="bg-blue-500">{user?.displayName ? user.displayName[0].toUpperCase() : (user?.email?.[0].toUpperCase() || "U")}</AvatarFallback>
                  </Avatar>
                </TooltipTrigger>
                <TooltipContent side="right">{user?.displayName || user?.email}</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={async () => {
                      await signOut();
                      navigate("/auth/signin");
                    }}
                  >
                    <LogOut className="h-6 w-6" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">Log out</TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}

        {/* Expand/Collapse Button */}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="custom"
              className={cn(
                "fixed top-8 z-50 rounded-full bg-yellow-vivid shadow-md transition-all duration-300 hover:bg-yellow-vivid",
                isExpanded ? "left-[375px]" : "left-[55px]"
              )}
              onClick={handleToggleSidebar}
            >
              <ChevronRight
                className={cn("h-4 w-4 bg-yellow-vivid transition-transform", isExpanded && "rotate-180")}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right">{isExpanded ? "Collapse Sidebar" : "Expand Sidebar"}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};
