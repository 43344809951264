import React, { useEffect, useCallback } from "react";
import { useAuthStore } from "@/features/auth/auth-store";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { useSensorStore } from "@/features/dashboard/sensor-store";
import { useMediaQuery } from "@/hooks/use-media-query";
import { cn } from "@/components/utils";
import { StatusSection } from "./status/StatusSection";
import { StatusSectionSkeleton, DeviceContentSkeleton } from "./DashboardSkeletons";
import { DashboardSidebar } from "./sidebar/DashboardSidebar";
import { DeviceContent } from "./device/DeviceContent";
import { useNavigate, useSearchParams } from "react-router-dom";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId"); 
  const isNewUser = useAuthStore(useCallback((state) => state.isNewUser, []));

  const { status, isExpanded,  selectedDevice, devices, customerDevices, setDrawerOpen, showGauges, setShowGauges } = useDashboardStore(
    useCallback(
      (state) => ({
        setShowGauges: state.setShowGauges,
        showGauges: state.ui.showGauges,
        status: state.ui.status,
        isExpanded: state.isExpanded,      
        selectedDevice: state.selectedDevice,
        devices : state.devices,
        customerDevices: state.customerDevices,
        setDrawerOpen: state.setDrawerOpen 
      }),
      []
    )
  );

  useEffect(() => {
       
    if (!isExpanded) {
      setDrawerOpen(true);
    }
    if (!showGauges) {
      setShowGauges(true);
    }
    if (!deviceId) {
      if (devices && devices.length > 0) {
        console.log("Auto-selecting device", devices[0].deviceId);
        navigate(`/dashboard?deviceId=${devices[0].deviceId}`);       
        return;
      }           
        
      if (customerDevices && customerDevices.length > 0) {  
        const customerWithDevices =  customerDevices.filter((cd) => (cd.devices && cd.devices.length > 0 ));
        if (customerWithDevices.length > 0) {    
          console.log("Auto-selecting customer device", customerWithDevices[0].devices[0].deviceId);
          navigate(`/dashboard?deviceId=${customerWithDevices[0].devices[0].deviceId}`);          
        }
        return;
      } 
    }   
    
  }, [devices, customerDevices]);
  
    
  return (
    <div className="flex min-h-screen">
      <DashboardSidebar />
      <main
        className={cn(
          "flex-1 transition-all duration-300 overflow-x-hidden",
          isMobile ? "ml-0 px-2" : isExpanded ? "ml-[390px]" : "ml-16",
          "py-4"
        )}
      >
        <div className="relative w-full max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8">
          <div className={cn("space-y-4 sm:space-y-6", isMobile ? "mt-20" : "")}>
            {selectedDevice && (
              <>
                <StatusSection isNewUser={isNewUser} status={status} />
                <DeviceContent selectedDevice={selectedDevice} />
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};
