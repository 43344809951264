import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { capitalize } from "../../../helpers";


export const DeviceInfoCard = ({ device }) => (
  <Card>
    <CardHeader>
      <CardTitle>Device Information</CardTitle>
    </CardHeader>
    <CardContent className="space-y-2">
      <div className="flex justify-between">
        <span>Name:</span>
        <span className="font-semibold">{device.name}</span>
      </div>
      <div className="flex justify-between">
        <span>Model:</span>
        <span className="font-semibold">
          {device.monitoring?.brand} {device.monitoring?.tonnage} Ton
        </span>
      </div>
      <div className="flex justify-between">
        <span>Refrigerant:</span>
        <span className="font-semibold">{device.monitoring?.refrigerant}</span>
      </div>
      <div className="flex justify-between">
        <span>Heat Pump:</span>
        <span className="font-semibold">{device.monitoring?.heatPump ? "Yes" : "No"}</span>
      </div>
      <div className="flex justify-between">
        <span>Efficiency:</span>
        <span className="font-semibold">{capitalize(device.monitoring?.efficiency.replaceAll("-"," "))}</span>
      </div>
    </CardContent>
  </Card>
);
