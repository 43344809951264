export function getInteger(value): number | null {
  try {
    if (isNaN(value)) {
      return null;
    }
    let integer = parseInt(value);
    if (integer.toString() === value) return integer;
    return null;
  } catch (e) {
    return null;
  }
}

export function capitalize(value: string) {
  if (value && typeof value == "string") {
    let afterDoubleSpaces: string = String(value).toLowerCase().trim();
    let beforeDoubleSpaces: string | null = null;
    do {
      beforeDoubleSpaces = afterDoubleSpaces;
      afterDoubleSpaces = beforeDoubleSpaces.replace("  ", " ");
    } while (afterDoubleSpaces.length < beforeDoubleSpaces.length);
    let parts: string[] = afterDoubleSpaces.split(" ");
    let response: string = "";
    for (let part of parts) {
      response = response + part.charAt(0).toUpperCase() + String(part).slice(1) + " ";
    }
    return response.trim();
  }
  return value;
}
