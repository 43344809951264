import React, { useCallback } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { ResponsiveContainer } from "recharts";
import { formatDistance } from "date-fns";
import { DigitalValues } from "@/features/dashboard/echart/digitalValues";
import { SuperHeat } from "@/features/dashboard/gauges/SuperHeat";
import { SubCooling } from "@/features/dashboard/gauges/SubCooling";
import { EChartsStreamVisualizer } from "@/features/dashboard/echart/EChartsStreamVisualizer";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { useLastSensorUpdate, useSensorStore, useSensorStream } from "@/features/dashboard/sensor-store";
import { currentOptions, highLineOptions, lowLineOptions } from "../ChartConfigurationOptions";
import { RechartsStreamVisualizer } from "../RechartsStreamVisualizer";

// Memoized SensorCard component
const SensorCard = ({ title, sensorStream, children, lastUpdate }) => {  
  return (
  <Card className="py-4 sm:p-4 relative shadow-sm md:col-span-1 2xl:col-span-4">
    <CardHeader className="p-0">
      <CardTitle className="flex pb-2 sm:pb-6 justify-center font-semibold leading-none tracking-tight">
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent className="sm:items-center p-0 bg-transparent">
      <div className="flex w-full justify-center">
        <div className="w-full absolute sm:w-1/2 z-100 pt-[88px]">{children}</div>
        <ResponsiveContainer width="100%" height="100%" className="flex items-stretch">
          <EChartsStreamVisualizer
            options={title === "Low Line" ? lowLineOptions : title === "High Line" ? highLineOptions : currentOptions}
            data$={sensorStream}
            minHeight="260px"
            minWidth="250px"
          />
        </ResponsiveContainer>
      </div>
    </CardContent>
    <CardDescription className="flex text-xs sm:text-sm justify-center pt-10 text-slate-400">
      {`Last update: ${(lastUpdate && !isNaN(lastUpdate)) ? formatDistance(lastUpdate, new Date()) : "-"}`}
    </CardDescription>
  </Card>
  );
};

// Memoized HistoricalDataCard component
const HistoricalDataCard = ({ lowLine$, highLine$, current$ }) => (
  <div className="mt-10">
  <Card>
    <CardHeader>
      <CardTitle>Recent Historical Data</CardTitle>
    </CardHeader>
    <CardContent>
      <RechartsStreamVisualizer lowLine$={lowLine$} highLine$={highLine$} current$={current$} />
    </CardContent>
  </Card>
  </div>
);

// Memoized content components
const NoDeviceSelected = React.memo(() => (
  <div className="flex flex-col items-center justify-center h-full">
    <h3 className="text-2xl text-foreground dark:text-blue-brand">Select a device to view analytics</h3>
  </div>
));

const NoSubscription = React.memo(() => (
  <div className="flex flex-col items-center justify-center h-full">
    <h3 className="text-2xl text-foreground dark:text-blue-brand">No active subscription</h3>
    <p className="text-base text-muted-foreground dark:text-blue-brand py-6">Please subscribe to a plan to view data</p>
  </div>
));

// Main historical data content component
const HistoricalDataContent = ({ selectedDevice, showMetric }) => {
  const lowLine$ = useSensorStream("lowLine");
  const highLine$ = useSensorStream("highLine");
  const currents$ = useSensorStream("currents");

  const lastLowLineUpdate = useLastSensorUpdate("lowLine");
  const lastHighLineUpdate = useLastSensorUpdate("highLine");
  const lastCurrentUpdate = useLastSensorUpdate("currents");

  return (
    <div className="mt-6">
      <div className="md:grid lg:grid-cols-2 2xl:grid-cols-12 gap-8 space-y-4 sm:space-y-4 md:space-y-0">
        <SensorCard title="Low Line" sensorStream={lowLine$} lastUpdate={lastLowLineUpdate}>
          <span className="flex justify-center text-xs sm:text-sm w-full text-foreground text-center dark:text-blue-brand">
            {selectedDevice.monitoring?.refrigerant ?? "unknown"}
          </span>
          <DigitalValues data={{ sensorId: "pressureLow" }} data$={lowLine$} />
          <DigitalValues data={{ sensorId: "temperatureLow" }} data$={lowLine$} />
          <SuperHeat
            data$={lowLine$}
            temperatureSensorId="temperatureLow"
            pressureSensorId="pressureLow"
            showMetric={showMetric}
          />
        </SensorCard>

        <SensorCard title="High Line" sensorStream={highLine$} lastUpdate={lastHighLineUpdate}>
          <span className="flex justify-center text-xs sm:text-sm w-full text-foreground text-center dark:text-blue-brand">
            {selectedDevice.monitoring?.refrigerant ?? "unknown"}
          </span>
          <DigitalValues data={{ sensorId: "pressureHigh" }} data$={highLine$} />
          <DigitalValues data={{ sensorId: "temperatureHigh" }} data$={highLine$} />
          <SubCooling
            data$={highLine$}
            temperatureSensorId="temperatureHigh"
            pressureSensorId="pressureHigh"
            showMetric={showMetric}
          />
        </SensorCard>

        <SensorCard title="Current" sensorStream={currents$} lastUpdate={lastCurrentUpdate}>
          <div className="mt-[20px]"></div>
          <DigitalValues data={{ sensorId: "current1", name: "Compressor" }} data$={currents$} />
          <DigitalValues data={{ sensorId: "current2", name: "Fan" }} data$={currents$} />
        </SensorCard>
      </div>

      <HistoricalDataCard lowLine$={lowLine$} highLine$={highLine$} current$={currents$} />
    </div>
  );
};

// Main component
export const DeviceHistoricalData = () => {
  // Split state selections
  const { selectedDevice, showMetric } = useDashboardStore(
    useCallback(
      (state) => ({
        selectedDevice: state.selectedDevice,
        showMetric: state.ui.showMetric,
      }),
      []
    )
  );

  if (!selectedDevice) {
    return <NoDeviceSelected />;
  }

  if (!selectedDevice.subscribed) {
    return <NoSubscription />;
  }

  const isLoading = useSensorStore(useCallback((state) => state.isLoading,[]));

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-center space-y-3">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary mx-auto"></div>
          <div>Loading gauges data...</div>
        </div>
      </div>
    );
  }

  return <HistoricalDataContent selectedDevice={selectedDevice} showMetric={showMetric} />;
};
