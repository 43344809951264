import { useUnitConversion } from "@/hooks/useUnitConversion";
import React from "react";
import { useEffect } from "react";

export const DigitalValues = ({ data, data$ }) => {
  const [value, setValue] = React.useState<number>(0);
  const { convertValue, getUnit } = useUnitConversion();

  useEffect(() => {
    let subscription = null;
    if (data$) {
      subscription = data$.subscribe((d) => {
        const values = d[data.sensorId];
        if (!values) {
          console.warn("No values for sensor", data.sensorId, "data", d);
          return;
        }
        setValue(Number(values[values.length - 1].value));
      });
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [data$, data.sensorId]);

  const sensorType = data.sensorId.includes("temperature")
    ? "temperature"
    : data.sensorId.includes("pressure")
    ? "pressure"
    : "current";

  const displayValue = convertValue(value, sensorType);
  const unit = getUnit(sensorType);

  return (
    <div className="flex w-full flex-row items-center justify-center">
      <span className="flex text-4xl font-bold text-foreground dark:text-blue-brand justify-start pr-2">
        {displayValue.toFixed(0)}
      </span>
      <span className="flex text-md font-normal text-foreground dark:text-blue-brand justify-end">{unit}</span>
    </div>
  );
};

/*
Formula to change measure system
 °C = (°F - 32) × 5/9.
1 kPa = 0.1450377377 psi
      {showMetric ? calculateUnits(value, data.sensorId) : value.toFixed(1)}
*/
